import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

const Portal = (props) => {
  const { container = global?.document?.body, children } = props;

  return container ? createPortal(children, container) : null;
};

Portal.propTypes = {
  container: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.shape({})]),
  children: PropTypes.node.isRequired,
};

Portal.defaultProps = {
  container: undefined,
};

export default Portal;
