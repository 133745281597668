import { useMemo } from 'react';
import { configureStore, isRejectedWithValue } from '@reduxjs/toolkit';
import review from './review/slice';
import search from './search/slice';
import user from './user/slice';
import enums from './enums/slice';
import projects from './projects/slice';
import projectCreate from './projectCreate/slice';
import clients from './clients/slice';
import clientCompanies from './clientCompanies/slice';
import clientCompaniesInfo from './clientCompaniesInfo/slice';
import clientProducts from './clientProducts/slice';
import clientProductInfo from './clientProductInfo/slice';
import clientAgreements from './clientAgreements/slice';
import agreement from './agreement/slice';
import agreementDocuments from './agreementDocuments/slice';
import agreementDocumentInfo from './agreementDocumentInfo/slice';
import salaryBalance from './salaryBalance/slice';
import vacation from './vacation/slice';
import vacationHistory from './vacationHistory/slice';
import vacationRequests from './vacationRequests/slice';
import salaryPayments from './salaryPayments/slice';
import salaryLoanBalance from './salaryLoanBalance/slice';
import agreementServices from './agreementServices/slice';
import agreementServiceInfo from './agreementServiceInfo/slice';
import milestones from './milestones/slice';
import milestoneInfo from './milestoneInfo/slice';
import clientProductProjects from './clientProductProjects/slice';
import conversion from './conversion/slice';
import notifications from './notifications/slice';
import systemsPermissions from './systemsPermissions/slice';
import systemsHolidays from './systemsHolidays/slice';
import clientFeed from './clientFeed/slice';
import milestoneItems from './milestoneItems/slice';
import systemsTrackers from './systemsTrackers/slice';
import assetsCategories from './assetsCategories/slice';
import salaryCorrectionType from './salaryCorrectionType/slice';
import systemsCVGenerator from './systemsCVGenerator/slice';
import milestoneItemInfo from './milestoneItemInfo/slice';
import projectsVacation from './projectsVacation/slice';
import helpCenter from './helpCenter/slice';
import reportVacations from './reportVacations/slice';
import reportEmployees from './reportEmployees/slice';
import workingHours from './workingHours/slice';
import employees from './employees/slice';
import userHoverCard from './userHoverCard/slice';
import salaryBonusCategory from './salaryBonusCategory/slice';
import invoiceItemCategory from './invoiceItemCategory/slice';
import fopInfo from './fopInfo/slice';
import roles from './roles/slice';
import roleInfo from './roleInfo/slice';
import applications from './applications/slice';
import applicationInfo from './applicationInfo/slice';
import invoiceHistory from './invoiceHistory/slice';
import timesheet from './timesheet/slice';
import projectTickets from './projectTickets/slice';
import projectSingleTicket from './projectSingleTicket/slice';
import systemsDepartments from './systemsDepartments/slice';
import subscriptions from './subscriptions/slice';
import techleading from './techleading/slice';
import techleadingRecommended from './techleadingRecommended/slice';
import systemsPositions from './systemsPositions/slice';
import worklog from './worklog/slice';
import salaryPaymentBankAccount from './salaryPaymentBankAccount/slice';
import paymentMethods from './paymentMethods/slice';
import countryCode from './countryCode/slice';
import globalSearch from './globalSearch/slice';
import expenseReports from './expenseReports/slice';
import financeRate from './financeRate/slice';
import systemsPositionsInfo from './systemsPositionsInfo/slice';
import assetInfo from './assetInfo/slice';
import assets from './assets/slice';
import buildReportStats from './buildReportStats/slice';
import clientFeedback from './clientFeedback/slice';

import notificationsMiddleware from './notifications/middleware';
import reviewMiddleware from './review/middleware';
import projectsMiddleware from './projects/middleware';

let store;

export const setupStore = (preloadedState) => {
  return configureStore({
    reducer: {
      search,
      review,
      clientAgreements,
      projects,
      enums,
      user,
      salaryBalance,
      clients,
      agreementDocuments,
      clientCompanies,
      clientCompaniesInfo,
      agreement,
      salaryPayments,
      agreementDocumentInfo,
      agreementServices,
      vacation,
      vacationHistory,
      vacationRequests,
      clientProducts,
      clientProductInfo,
      salaryLoanBalance,
      milestones,
      milestoneInfo,
      clientProductProjects,
      conversion,
      notifications,
      systemsPermissions,
      systemsHolidays,
      agreementServiceInfo,
      clientFeed,
      milestoneItems,
      assetsCategories,
      salaryCorrectionType,
      systemsCVGenerator,
      systemsTrackers,
      milestoneItemInfo,
      projectCreate,
      projectsVacation,
      helpCenter,
      reportVacations,
      workingHours,
      reportEmployees,
      employees,
      userHoverCard,
      salaryBonusCategory,
      invoiceItemCategory,
      fopInfo,
      roles,
      roleInfo,
      applications,
      applicationInfo,
      invoiceHistory,
      timesheet,
      projectTickets,
      projectSingleTicket,
      systemsDepartments,
      subscriptions,
      techleading,
      techleadingRecommended,
      systemsPositions,
      financeRate,
      worklog,
      salaryPaymentBankAccount,
      paymentMethods,
      countryCode,
      globalSearch,
      expenseReports,
      systemsPositionsInfo,
      assetInfo,
      assets,
      buildReportStats,
      clientFeedback
    },
    preloadedState,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        serializableCheck: {
          isSerializable: () => isRejectedWithValue(),
          ignoredPaths: ['agreementDocumentInfo.documentFields'],
        },
      })
        .concat([
          notificationsMiddleware,
          reviewMiddleware,
          projectsMiddleware,
        ]);
    },
  });
};

export const initializeStore = (preloadedState) => {
  let initialStore = store ?? setupStore(preloadedState);

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    initialStore = setupStore({
      ...store.getState(),
      ...preloadedState,
    });
    // Reset the current store
    store = undefined;
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return initialStore;
  // Create the store once in the client
  if (!store) store = initialStore;

  return initialStore;
};

export function useStore(initialState) {
  return useMemo(() => initializeStore(initialState), [initialState]);
}
