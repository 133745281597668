import {
  COMMENT_SET_ABORT, FETCH_COMMENTS, SET_COMMENT_PROPS, SET_COMMENT_LOG_ERROR, SET_COMMENT_MODAL_DATA,
  TOGGLE_COMMENT_ACTION, COMMENT_SET_LOADED, RESET_COMMENT_MODAL_DATA, COMMENT_SET_LOADED_ITEM
} from './types';

export const initialState = {
  commentsData: [],
  modalErrors: {},
  commentIsLoaded: false,
  isCommentItemLoading: false,
  abortProps: {
    abortComments: {},
  },
  commentProps: {
    commentable_type: '',
    commentable_id: null,
  },
  commentModal: {
    modalId: 'commentModal',
    isModalOpen: false,
    isUpdateData: false,
    date: '',
    commentId: '',
    comment: '',
    comment_json: undefined,
    editCommentJson: undefined,
    employeeName: '',
    status: 'very-good',
    roles: ['Admin'],
    client: {},
    isProjectOpen: false,
    project: null,
    problem: '',
  },
  commentWarningModal: {
    modalId: 'commentWarningModal',
    isModalOpen: false,
  },
  moreProps: {
    isOpen: false,
    id: '',
    style: {},
  },
  editCommentModal: {
    isModalOpen: false,
    modalId: 'editCommentModal',
  },
};

export default function comments(state = initialState, action) {
  switch (action.type) {
    case RESET_COMMENT_MODAL_DATA:
      return {
        ...state,
        commentModal: initialState.commentModal,
        modalErrors: initialState.modalErrors,
      };
    case COMMENT_SET_ABORT:
      return {
        ...state,
        abortProps: {
          ...state.abortProps,
          [action.key]: action.controller
        }
      };
    case SET_COMMENT_LOG_ERROR:
      return {
        ...state,
        modalErrors: action.modalErrors,
      };
    case SET_COMMENT_MODAL_DATA:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          ...action.data
        }
      };
    case FETCH_COMMENTS:
      return {
        ...state,
        commentsData: action.commentsData
      };
    case TOGGLE_COMMENT_ACTION:
      return {
        ...state,
        moreProps: {
          ...state.moreProps,
          ...action.moreProps
        }
      };
    case SET_COMMENT_PROPS:
      return {
        ...state,
        commentProps: {
          ...state.commentProps,
          ...action.data
        }
      };
    case COMMENT_SET_LOADED:
      return {
        ...state,
        commentIsLoaded: action.isLoaded,
      };
    case COMMENT_SET_LOADED_ITEM:
      return {
        ...state,
        isCommentItemLoading: action.isLoaded,
      };
    default:
      return state;
  }
}
