// All permissions
export const ALL_USERS = 'all_users';
export const SALARY_BASE_MANAGEMENT = 'salary_base.management';
export const SALARY_BASE_MANAGEMENT_OWN = 'salary_base.management.own';
export const SALARY_BONUS_MANAGEMENT = 'salary_bonus.management';
export const SALARY_CORRECTION_MANAGEMENT = 'salary_correction.management';
export const SALARY_FOP_MANAGEMENT = 'salary_fop.management';
export const SALARY_MANAGEMENT = 'salary.management';
export const HOLIDAYS_MANAGEMENT = 'holidays.management';
export const HOLIDAYS_READ = 'holidays.read';
export const DEPARTMENTS_MANAGEMENT = 'departments.management';
export const DEPARTMENTS_READ = 'departments.read';
export const PERMISSION_MANAGEMENT = 'permission.management';
export const PERMISSION_READ = 'permission.read';
export const TECHNOLOGIES_MANAGEMENT = 'technologies.management';
export const TECHNOLOGIES_READ = 'technologies.read';
export const USER_TECHNOLOGIES_MANAGEMENT = 'user_technologies.management';
export const MANAGERS_HISTORY_MANAGEMENT = 'managers_history.management';
export const MANAGERS_HISTORY_READ = 'managers_history.read';
export const PROJECTS_MANAGEMENT = 'projects.management';
export const PROJECTS_MANAGEMENT_OWN = 'projects.management.own';
export const PROJECTS_SET_REVIEW_BY_USER_MANAGER = 'projects.set_review_by_user_manager';
export const PROJECTS_READ_OWN = 'projects.read.own';
export const PROJECTS_READ = 'projects.read';
export const PROJECTS_ITEMS_MANAGEMENT = 'projects.items.management';
export const PROJECTS_INNER_ITEMS_MANAGEMENT = 'projects.*.items.management';
export const PROJECTS_PEOPLE_MANAGEMENT = 'projects.people.management';
export const PROJECTS_TICKETS_MANAGEMENT = 'projects.tickets.management';
export const PROJECTS_TICKETS_MANAGEMENT_OWN = 'projects.tickets.management.own';
export const PROJECTS_TICKETS_READ = 'projects.tickets.read';
export const PROJECTS_TICKETS_READ_OWN = 'projects.tickets.read.own';
export const PROJECTS_TICKETS_PAGE_PERMISSIONS = [
  PROJECTS_TICKETS_READ, PROJECTS_TICKETS_MANAGEMENT, PROJECTS_TICKETS_MANAGEMENT_OWN, PROJECTS_TICKETS_READ_OWN
];
export const PROJECTS_WORKLOGS_MANAGEMENT = 'projects.worklogs.management';
export const REVIEW = 'review.all';
export const REVIEW_OWN = 'review.own';
export const TIMESHEET = 'timesheet';
export const TIMESHEET_OWN = 'timesheet.own';
export const WORKLOG_MANAGEMENT = 'worklog_manage.management';
export const USER_FLAGS_MANAGEMENT = 'user_flags.management';
export const INVOICES_MANAGEMENT = 'invoices.management';
export const INVOICES_MANAGEMENT_OWN = 'invoices.management.own';
export const VACATION_CONFIRMATION = 'vacation.confirmation';
export const VACATION_MANAGEMENT = 'vacation.management';
export const ASSETS_MANAGEMENT = 'assets.management';
export const ASSETS_READ = 'assets.read';
export const EXPENSE_REPORT_MANAGEMENT_OWN = 'expense_report.management.own';
export const EXPENSE_REPORT_MANAGEMENT = 'expense_report.management';
export const EXPENSE_REPORT_READ_OWN = 'expense_report.read.own';
export const EXPENSE_REPORT_READ = 'expense_report.read';
export const REPORT_TIMETRACKING_ALL = 'report.time_tracking.all';
export const REPORT_TIMETRACKING_OWN = 'report.time_tracking.own';
export const REPORT_EMPLOYEES_ALL = 'report.employee_reports';
export const REPORT_BUILD_REPORT_STATS = 'report.build_report_stats';
export const CV_MANAGEMENT = 'cv.management';
export const CV_QUALITIES_MANAGEMENT = 'cv_qualities.management';
export const SALARY_PAYMENT_MANAGEMENT = 'salary_payment.management';
export const HELP_ARTICLE_MANAGEMENT = 'help_articles.management';
export const HELP_ARTICLE_READ = 'help_articles.read';

// WAITING_LIST PERMISSIONS
export const WAITING_LIST_READ = 'waiting_list.read';
export const WAITING_LIST_MANAGMENT = 'waiting_list.management';
export const WAITING_LIST_MANAGMENT_OWN = 'waiting_list.management.own';
export const WAITING_LIST_PAGE_PERMISSIONS = [WAITING_LIST_READ, WAITING_LIST_MANAGMENT, WAITING_LIST_MANAGMENT_OWN];

// ROLES PERMISSIONS
export const ROLES_MANAGEMENT = 'roles.management';
export const ROLES_READ = 'roles.read';
export const ROLES_PAGE_PERMISSIONS = [ROLES_MANAGEMENT, ROLES_READ];

// COMMENTS PERMISSIONS
export const COMMENTS_MANAGEMENT = 'comments.management';

// USERS PERMISSIONS
export const USERS_EFFICIENCY = 'users.efficiency';
export const USERS_MANAGEMENT = 'users.management';
export const USERS_MANAGEMENT_MENTORS = 'users.management.mentors';
export const USERS_READ = 'users.read';
export const USERS_STATISTIC = 'users.statistic';
export const USERS_COMMENTS_WRITE = 'users.comments.write';
export const USERS_COMMENTS_READ = 'users.comments.read';

// USERS PAGE PERMISSIONS
export const USERS_COMMENTS_PAGE_PERMISSIONS = [
  USERS_COMMENTS_READ, USERS_COMMENTS_WRITE, USERS_MANAGEMENT, COMMENTS_MANAGEMENT
];
export const USERS_STATISTIC_PAGE_PERMISSIONS = [USERS_EFFICIENCY, USERS_STATISTIC];

// APPLICATIONS PERMISSIONS
export const APPLICATIONS_MANAGEMENT = 'applications.management';
export const APPLICATIONS_READ = 'applications.read';
export const APPLICATIONS_PAGE_PERMISSIONS = [APPLICATIONS_MANAGEMENT, APPLICATIONS_READ];

// SALARY BALANCE PERMISSIONS
export const SALARY_BALANCE_READ = 'salary_balance.read';
export const SALARY_BALANCE_READ_OWN = 'salary_balance.read.own';
export const SALARY_BALANCE_PAGE_PERMISSIONS = [SALARY_BALANCE_READ, SALARY_BALANCE_READ_OWN];

// SALARY FOP INFO
export const FOP_INFO_MANAGEMENT = 'fop_info.management';
export const FOP_INFO_RED = 'fop_info.read';
export const SALARY_FOP_INFO_PAGE_PERMISSIONS = [FOP_INFO_MANAGEMENT, FOP_INFO_RED];

// CLIENTS
export const CLIENTS_MANAGEMENT = 'clients.management';
export const CLIENTS_MANAGEMENT_OWN = 'clients.management.own';
export const CLIENTS_MERGE = 'clients.merge';
export const CLIENTS_READ = 'clients.read';
export const CLIENTS_READ_OWN = 'clients.read.own';
export const CLIENTS_STATISTIC = 'clients.statistic';
export const CLIENTS_STATISTIC_OWN = 'clients.statistic.own';

// CLIENT COMPANIES
export const CLIENT_COMPANIES_MANAGEMENT = 'client_companies.management';
export const CLIENT_COMPANIES_MANAGEMENT_OWN = 'client_companies.management.own';
export const CLIENT_COMPANIES_READ = 'client_companies.read';
export const CLIENT_COMPANIES_READ_OWN = 'client_companies.read.own';

// CLIENT AGREEMENT
export const CLIENT_AGREEMENT_MANAGEMENT = 'client_agreement.management';
export const CLIENT_AGREEMENT_MANAGEMENT_OWN = 'client_agreement.management.own';
export const CLIENT_AGREEMENT_READ = 'client_agreement.read';
export const CLIENT_AGREEMENT_READ_OWN = 'client_agreement.read.own';

// CLIENT AGREEMENT DOCUMENT
export const CLIENT_AGREEMENT_DOCUMENT_MANAGEMENT = 'client_agreement_document.management';
export const CLIENT_AGREEMENT_DOCUMENT_MANAGEMENT_OWN = 'client_agreement_document.management.own';
export const CLIENT_AGREEMENT_DOCUMENT_READ = 'client_agreement_document.read';
export const CLIENT_AGREEMENT_DOCUMENT_READ_OWN = 'client_agreement_document.read.own';

// CLIENT AGREEMENT SERVICE
export const CLIENT_AGREEMENT_SERVICE_MANAGEMENT = 'client_agreement_service.management';
export const CLIENT_AGREEMENT_SERVICE_MANAGEMENT_OWN = 'client_agreement_service.management.own';
export const CLIENT_AGREEMENT_SERVICE_READ = 'client_agreement_service.read';
export const CLIENT_AGREEMENT_SERVICE_READ_OWN = 'client_agreement_service.read.own';

// CLIENT PRODUCT
export const CLIENT_PRODUCT_MANAGEMENT = 'client_product.management';
export const CLIENT_PRODUCT_MANAGEMENT_OWN = 'client_product.management.own';
export const CLIENT_PRODUCT_READ = 'client_product.read';
export const CLIENT_PRODUCT_READ_OWN = 'client_product.read.own';

// CLIENT PRODUCT MILESTONE
export const CLIENT_PRODUCT_MILESTONE_MANAGEMENT = 'client_product_milestone.management';
export const CLIENT_PRODUCT_MILESTONE_MANAGEMENT_OWN = 'client_product_milestone.management.own';
export const CLIENT_PRODUCT_MILESTONE_READ = 'client_product_milestone.read';
export const CLIENT_PRODUCT_MILESTONE_READ_OWN = 'client_product_milestone.read.own';

// CLIENT PRODUCT MILESTONE ITEM

export const CLIENT_PRODUCT_MILESTONE_ITEM_MANAGEMENT = 'client_product_milestone_item.management';
export const CLIENT_PRODUCT_MILESTONE_ITEM_MANAGEMENT_OWN = 'client_product_milestone_item.management.own';
export const CLIENT_PRODUCT_MILESTONE_ITEM_READ = 'client_product_milestone_item.read';
export const CLIENT_PRODUCT_MILESTONE_ITEM_READ_OWN = 'client_product_milestone_item.read.own';

// CLIENT COMMENTS MILESTONE
export const CLIENT_COMMENTS_MANAGEMENT = 'client_comments.management';
export const CLIENT_COMMENTS_MANAGEMENT_OWN = 'client_comments.management.own';
export const CLIENT_COMMENTS_READ = 'client_comments.read';
export const CLIENT_COMMENTS_READ_OWN = 'client_comments.read.own';

// SUBSCRIPTIONS
export const SUBSCRIPTIONS_MANAGEMENT = 'subscriptions.management';
export const SUBSCRIPTIONS_MANAGEMENT_OWN_DEPARTMENT = 'subscriptions.management.own.department';
export const SUBSCRIPTIONS_MANAGEMENT_OWN = 'subscriptions.management.own';
export const SUBSCRIPTIONS_READ = 'subscriptions.read';
export const SUBSCRIPTIONS_READ_OWN = 'subscriptions.read.own';
export const SUBSCRIPTIONS_READ_OWN_DEPARTMENT = 'subscriptions.read.own.department';

// TEACHLEADING
export const USER_TEACHLEAD_MANAGEMENT = 'user_teachlead.management';
export const USER_TEACHLEAD_MANAGEMENT_OWN = 'user_teachlead.management.own';
// SYSTEMS POSITIONS
export const POSITIONS_READ = 'positions.read';
export const POSITIONS_MANAGEMENT = 'positions.management';

export const USER_POSITIONS_READ = 'user_positions.read';
export const USER_POSITIONS_MANAGEMENT = 'user_positions.management';

// Converter
export const CONVERT_UPWORK_TRANSACTIONS = 'convert_upwork_transactions';
export const CONVERT_PAGE_PERMISSIONS = [CONVERT_UPWORK_TRANSACTIONS];

// SALARY PAYMENT BANK ACCOUNT
export const SALARY_PAYMENT_BANK_ACCOUNT_MANAGEMENT = 'salary_payment_bank_accounts.management';
export const SALARY_PAYMENT_BANK_ACCOUNT_READ = 'salary_payment_bank_accounts.read';
export const SALARY_PAYMENT_BANK_ACCOUNT_PAGE_PERMISSIONS = [
  SALARY_PAYMENT_BANK_ACCOUNT_MANAGEMENT, SALARY_PAYMENT_BANK_ACCOUNT_READ
];

// SALARY FINANCE RATE
export const FINANCE_RATE_MANAGEMENT = 'finance_rate.management';
export const FINANCE_RATE_READ = 'finance_rate.read';
export const FINANCE_RATE_PAGE_PERMISSIONS = [FINANCE_RATE_MANAGEMENT, FINANCE_RATE_READ];

// PAYMENT METHODS
export const PAYMENT_METHODS_MANAGEMENT = 'payment_methods.management';
export const PAYMENT_METHODS_READ = 'payment_methods.read';
export const PAYMENT_METHODS_PAGE_PERMISSIONS = [PAYMENT_METHODS_MANAGEMENT, PAYMENT_METHODS_READ];

// JIRA TRACKERS
export const TRACKERS_MANAGEMENT = 'trackers.management';
export const TRACKERS_MANAGEMENT_OWN = 'trackers.management.own';
export const TRACKERS_READ = 'trackers.read';
export const TRACKERS_READ_OWN = 'trackers.read.own';
export const TRACKERS_PERMISSIONS = [
  TRACKERS_MANAGEMENT, TRACKERS_MANAGEMENT_OWN, TRACKERS_READ_OWN, TRACKERS_READ
];

// Pages permissions
export const COMPANIES_PAGE_PERMISSIONS = [
  CLIENT_COMPANIES_READ_OWN, CLIENT_COMPANIES_READ, CLIENT_COMPANIES_MANAGEMENT_OWN, CLIENT_COMPANIES_MANAGEMENT
];
export const AGREEMENT_PAGE_PERMISSIONS = [
  CLIENT_AGREEMENT_READ_OWN, CLIENT_AGREEMENT_READ, CLIENT_AGREEMENT_MANAGEMENT_OWN, CLIENT_AGREEMENT_MANAGEMENT
];

export const AGREEMENT_DOCUMENT_PAGE_PERMISSIONS = [
  CLIENT_AGREEMENT_DOCUMENT_MANAGEMENT, CLIENT_AGREEMENT_DOCUMENT_MANAGEMENT_OWN, CLIENT_AGREEMENT_DOCUMENT_READ,
  CLIENT_AGREEMENT_DOCUMENT_READ_OWN
];
export const AGREEMENT_SERVICE_PAGE_PERMISSIONS = [
  CLIENT_AGREEMENT_SERVICE_MANAGEMENT, CLIENT_AGREEMENT_SERVICE_MANAGEMENT_OWN, CLIENT_AGREEMENT_SERVICE_READ,
  CLIENT_AGREEMENT_SERVICE_READ_OWN
];
export const PRODUCT_PAGE_PERMISSIONS = [
  CLIENT_PRODUCT_READ_OWN, CLIENT_PRODUCT_READ, CLIENT_PRODUCT_MANAGEMENT_OWN, CLIENT_PRODUCT_MANAGEMENT
];

export const PRODUCT_MILESTONE_PAGE_PERMISSIONS = [
  CLIENT_PRODUCT_MILESTONE_MANAGEMENT, CLIENT_PRODUCT_MILESTONE_MANAGEMENT_OWN, CLIENT_PRODUCT_MILESTONE_READ,
  CLIENT_PRODUCT_MILESTONE_READ_OWN
];

export const PRODUCT_MILESTONE_ITEM_PAGE_PERMISSION = [
  CLIENT_PRODUCT_MILESTONE_ITEM_MANAGEMENT, CLIENT_PRODUCT_MILESTONE_ITEM_MANAGEMENT_OWN,
  CLIENT_PRODUCT_MILESTONE_ITEM_READ, CLIENT_PRODUCT_MILESTONE_ITEM_READ_OWN
];

export const PROJECTS_PAGE_PERMISSIONS = [PROJECTS_MANAGEMENT, PROJECTS_READ_OWN];
export const INVOICES_PAGE_PERMISSIONS = [INVOICES_MANAGEMENT, INVOICES_MANAGEMENT_OWN];
export const USERS_PAGE_PERMISSIONS = [USERS_MANAGEMENT, USERS_READ];
export const REVIEW_PAGE_PERMISSIONS = [REVIEW, REVIEW_OWN];
export const TIMESHEET_PAGE_PERMISSIONS = [TIMESHEET, TIMESHEET_OWN];
export const WORKLOG_PAGE_PERMISSIONS = [WORKLOG_MANAGEMENT];
export const SALARY_PAGE_PERMISSIONS = [ALL_USERS];
export const VACATION_PAGE_PERMISSIONS = [ALL_USERS];
export const EXPENSE_REPORT_PAGE_PERMISSIONS = [
  EXPENSE_REPORT_MANAGEMENT, EXPENSE_REPORT_MANAGEMENT_OWN, EXPENSE_REPORT_READ_OWN, EXPENSE_REPORT_READ
];
export const TIME_TRACKING_PAGE_PERMISSIONS = [REPORT_TIMETRACKING_ALL, REPORT_TIMETRACKING_OWN];
export const HOLIDAYS_PAGE_PERMISSIONS = [HOLIDAYS_MANAGEMENT, HOLIDAYS_READ];
export const DEPARTMENTS_PAGE_PERMISSIONS = [DEPARTMENTS_MANAGEMENT, DEPARTMENTS_READ];
export const ASSETS_PAGE_PERMISSIONS = [ASSETS_MANAGEMENT, ASSETS_READ];
export const SYSTEMS_PAGE_PERMISSIONS = [
  ...HOLIDAYS_PAGE_PERMISSIONS, PERMISSION_MANAGEMENT, ...ROLES_PAGE_PERMISSIONS, ...DEPARTMENTS_PAGE_PERMISSIONS,
  ...ASSETS_PAGE_PERMISSIONS, SALARY_CORRECTION_MANAGEMENT, SALARY_BONUS_MANAGEMENT,
];
export const CV_GENERATOR_PAGE_PERMISSIONS = [ALL_USERS];
export const TECHNOLOGIES_PAGE_PERMISSIONS = [TECHNOLOGIES_READ, TECHNOLOGIES_MANAGEMENT];
export const CLIENT_REPORT_PERMISSIONS = [PROJECTS_MANAGEMENT, PROJECTS_MANAGEMENT_OWN];
export const SUBSCRIPTIONS_PAGE_PERMISSIONS = [
  SUBSCRIPTIONS_MANAGEMENT,
  SUBSCRIPTIONS_MANAGEMENT_OWN_DEPARTMENT,
  SUBSCRIPTIONS_MANAGEMENT_OWN,
  SUBSCRIPTIONS_READ,
  SUBSCRIPTIONS_READ_OWN,
  SUBSCRIPTIONS_READ_OWN_DEPARTMENT,
];
export const TEACHLEADING_PERMISSIONS = [USER_TEACHLEAD_MANAGEMENT, USER_TEACHLEAD_MANAGEMENT_OWN];
export const POSITIONS_PAGE_PERMISSIONS = [POSITIONS_READ, POSITIONS_MANAGEMENT];

export const USER_POSITIONS_PAGE_PERMISSIONS = [USER_POSITIONS_READ, USER_POSITIONS_MANAGEMENT];

export const CLIENTS_PAGE_PERMISSIONS = [CLIENTS_MANAGEMENT, CLIENTS_MANAGEMENT_OWN, CLIENTS_READ, CLIENTS_READ_OWN];
export const CLIENT_COMMENTS_PAGE_PERMISSIONS = [
  CLIENT_COMMENTS_READ, CLIENT_COMMENTS_READ_OWN, CLIENT_COMMENTS_MANAGEMENT, CLIENT_COMMENTS_MANAGEMENT_OWN
];
