import PropTypes from 'prop-types';

const Close = ({ width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line
        x1="7.27823"
        y1="7.44922"
        x2="17.1777"
        y2="17.3487"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="17.3486"
        y1="7.44937"
        x2="7.44914"
        y2="17.3489"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

Close.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Close.defaultProps = {
  width: 24,
  height: 24,
};

export default Close;
