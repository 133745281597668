// noinspection JSNonASCIINames

export const clientCommentsEmoji = [
  { status: 'good', emoji: '✅', ariaLabel: 'Check Mark' },
  { status: 'warning', emoji: '⚠️', ariaLabel: 'Warning' },
  { status: 'bad', emoji: '🛑', ariaLabel: 'Stop Sign' },
];

export const employeeCommentEmoji = [
  { status: 'very-good', emoji: '🔥' },
  { status: 'good', emoji: '😊' },
  { status: 'warning', emoji: '🤔' },
  { status: 'bad', emoji: '😣' },
  { status: 'very-bad', emoji: '😡' },
];

export const employeeWorkStatusEmoji = {
  vacation: '🌴',
  sickness: '🤒',
  dayOff: '🌚',
};
