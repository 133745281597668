import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { newValidateErrors, validateClientFeedback, validateErrors } from '../../utils/validators';
import axios from '../../services/axios';
import * as httpStatusCodes from '../../constants/httpStatusCodes';

export const initialState = {
  isLoading: true,
  isSubmit: false,
  error: undefined,
  data: {
    clutch: {
      icon: '/images/appIcons/ico-clutch.svg',
      feedback: [],
    },
    upwork: {
      icon: '/images/appIcons/ico-upwork.svg',
      feedback: [],
    },
    'video-feedback': {
      icon: '/images/appIcons/ico-video.svg',
      feedback: [],
    },
    'case-study': {
      icon: '/images/appIcons/ico-case-study.svg',
      feedback: [],
    },
    'works-portfolio': {
      icon: '/images/appIcons/ico-works.svg',
      feedback: [],
    }
  },
  query: {
    filters: {
      id: [],
      client: {
        id: [],
      },
      creator: {
        id: [],
        search: '',
      },
      source: '',
      link: '',
      activity: true,
    },
    with: [],
    sort: '',
  },
  feedbackFields: {
    link: '',
    description_json: {},
    isAddComment: false
  },
  fieldsErrors: {},
  openForm: '',
};

export const fetchFeedbackAsync = createAsyncThunk(
  'clientFeedback/fetchFeedback',
  async (_, { rejectWithValue, getState }) => {
    try {
      const { clientFeedback: { query } } = getState();
      const { data, status } = await axios.get('/client_feedback', { params: query });

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addOrEditFeedbackAsync = createAsyncThunk(
  'clientFeedback/addOrEditFeedback',
  async (arg, { getState, rejectWithValue, dispatch }) => {
    try {
      const { feedbackFields, query } = getState().clientFeedback;

      const errors = validateErrors(
        feedbackFields,
        validateClientFeedback,
      );

      if (errors) {
        return rejectWithValue({ isFormValidation: true, errors });
      }

      let method = 'POST';
      let url = '/client_feedback';

      if (query?.filters.id[0]) {
        method = 'PUT';
        url += `/${query.filters.id[0]}`;
      }

      const body = {
        client_id: query.filters.client.id[0],
        source: arg.source,
        link: feedbackFields.link,
        description_json: feedbackFields.description_json
      };

      const { data, status } = await axios({ method, url, data: body });

      if (status === 201 || status === 200) {
        dispatch(fetchFeedbackAsync());
        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteFeedbackAsync = createAsyncThunk(
  'clientFeedback/deleteFeedback',
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { feedbackId, source } = arg;
      const { data, status } = await axios.delete(
        `/client_feedback/${feedbackId}`,
      );

      if (status === httpStatusCodes.NO_CONTENT) {
        dispatch(fetchFeedbackAsync());
        return { ...data, feedbackId, source };
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const clientFeedbackSlice = createSlice({
  name: 'clientFeedback',
  initialState,
  reducers: {
    resetState: () => initialState,
    setQueryFilter: (state, action) => {
      state.query.filters = { ...state.query.filters, ...action.payload };
    },
    setQuery: (state, action) => {
      state.query = { ...state.query, ...action.payload };
    },
    setFieldsData: (state, action) => {
      state.feedbackFields = { ...state.feedbackFields, ...action.payload };
      state.fieldsErrors = {
        ...state.fieldsErrors,
        ...newValidateErrors({ ...action.payload }, validateClientFeedback),
      };
    },
    setOpenForm: (state, action) => {
      state.openForm = action.payload;
      state.fieldsErrors = {};
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFeedbackAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchFeedbackAsync.fulfilled, (state, action) => {
        state.isLoading = false;

        const dataCopy = { ...state.data };
        action.payload.data.forEach((item) => {
          const sourceKey = item.source;
          if (dataCopy[sourceKey]) {
            const exists = dataCopy[sourceKey].feedback.some((feedbackItem) => feedbackItem.id === item.id);

            if (!exists) {
              dataCopy[sourceKey].feedback.push(item);
            }
          }
        });

        state.data = dataCopy;
      })
      .addCase(fetchFeedbackAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(addOrEditFeedbackAsync.pending, (state) => {
        state.isSubmit = true;
        state.error = undefined;
      })
      .addCase(addOrEditFeedbackAsync.fulfilled, (state, action) => {
        state.isSubmit = false;
        state.openForm = '';
        state.feedbackFields = initialState.feedbackFields;
        state.fieldsErrors = {};
        state.query.filters = { ...state.query.filters, id: [] };

        if (action.payload.source) {
          const feedbackArray = state.data[action.payload.source].feedback;
          const feedbackIndex = feedbackArray.findIndex((item) => item.id === action.payload.id);
          if (feedbackIndex !== -1) {
            feedbackArray[feedbackIndex] = {
              ...feedbackArray[feedbackIndex],
              link: action.payload.link,
              description_json: action.payload.description_json || {},
            };
          }
          state.data[action.payload.source].feedback = feedbackArray;
        }
      })
      .addCase(addOrEditFeedbackAsync.rejected, (state, action) => {
        if (action.payload?.isFormValidation) {
          state.fieldsErrors = action.payload.errors;
        } else {
          state.error = action.payload;
        }
        state.isSubmit = false;
      })
      .addCase(deleteFeedbackAsync.pending, (state) => {
        state.isSubmit = true;
      })
      .addCase(deleteFeedbackAsync.fulfilled, (state, action) => {
        state.isSubmit = false;
        const { feedbackId, source } = action.payload;
        state.data[source].feedback = state.data[source].feedback.filter((item) => item.id !== feedbackId);
      })
      .addCase(deleteFeedbackAsync.rejected, (state, action) => {
        state.isSubmit = false;
        state.error = action.payload;
      });
  },
});

export const {
  resetState,
  setFieldsData,
  setQueryFilter,
  setQuery,
  setOpenForm,
} = clientFeedbackSlice.actions;

export default clientFeedbackSlice.reducer;
