export const apps = [
  {
    name: 'Projects',
    href: 'https://projects.hydra.office.onix.ua/',
    image: <img src="/images/appIcons/ico-projects.svg" alt="facebook icon" />
  },
  {
    name: 'Passport',
    href: 'https://passport.onix.hydra-systems.net/',
    image: <img src="/images/appIcons/ico-passport.svg" alt="passport icon" />
  },
  {
    name: 'Onix Map',
    href: 'https://map.onix-systems.com/',
    image: <img src="/images/appIcons/ico-onix-map.svg" alt="onix-map icon" />
  },
  {
    name: 'Knowledge',
    href: 'https://knowledge.onix.kr.ua/',
    image: <img src="/images/appIcons/ico-knowledge.svg" alt="knowledge icon" />
  },
  {
    name: 'Portfolio',
    href: 'https://works.onix-systems.com/',
    image: <img src="/images/appIcons/ico-portfolio.svg" alt="portfolio icon" />
  },
  {
    name: 'onix.team',
    href: 'https://onix.team/',
    image: <img src="/images/appIcons/ico-onix.svg" alt="onix icon" />
  },
  {
    name: 'Jira',
    href: 'https://onix-systems.atlassian.net/',
    image: <img src="/images/appIcons/ico-jira.svg" alt="jira icon" />
  },
  {
    name: 'Github',
    href: 'https://github.com/Onix-Systems/',
    image: <img src="/images/appIcons/ico-github.svg" alt="github icon" />
  },
  {
    name: 'GitLab',
    href: 'https://gitlab.onix.team/',
    image: <img src="/images/appIcons/ico-gitlab.svg" alt="gitlab icon" />
  },
  {
    name: 'Hydra.Help',
    href: 'https://help.onix.hydra-systems.net/',
    image: <img src="/images/appIcons/ico-hydra-help.svg" alt="help hydra icon" />
  },
];

export const medias = [
  {
    name: 'Telegram',
    href: 'https://t.me/onixkrua',
    image: <img src="/images/appIcons/ico-telegram.svg" alt="telegram icon" />
  },
  {
    name: 'YouTube',
    href: 'https://www.youtube.com/channel/UCTunqdlw-IY9yoBN4fA8lBA',
    image: <img src="/images/appIcons/ico-youtube.svg" alt="youtube icon" />
  },
  {
    name: 'Instagram',
    href: 'https://www.instagram.com/onix_team.ua',
    image: <img src="/images/appIcons/ico-instagram.svg" alt="instagram icon" />
  },
];
