import { PROJECT_ITEM_FIXED_PRICE_TYPE, PROJECT_ITEM_TM_TYPE } from '../constants/types';
import { MAX_SHORT_DESCRIPTION_LENGTH } from '../constants/values';
import {
  getProjectUnderNDA,
  isCardNumber,
  isDouble,
  isFormatDateDDMonYYYY,
  isFormatDateYYYYMMDD,
  isInteger,
  isLogTime,
  isLogTimeWithoutStep
} from './helpers';

const isMaxLength = (value, max = 255) => {
  const regStr = `^([\\s\\S]{0,${max}})$`;
  const regExp = new RegExp(regStr);

  return regExp.test(value);
};

const maxDecimalPart = (value, max) => {
  const regStr = `^[-]?\\d+(\\.[0-9]{0,${max}})?$`;
  const regExp = new RegExp(regStr);

  return regExp.test(value);
};

const maxIntegerPart = (value, max) => {
  const regStr = `^[-]?[0-9]{0,${max}}(\\.[0-9]+)?$`;
  const regExp = new RegExp(regStr);

  return regExp.test(value);
};

const isUrl = (value) => {
  const regStr = /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&/=]*)$/;
  const regExp = new RegExp(regStr);

  return regExp.test(value);
};

const isEmail = (value) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const regExp = new RegExp(emailRegex);

  return regExp.test(value);
};

const isSizeLess50Mb = (value) => value.file.size < 52428800;

export const fileExt = (fileName) => fileName.toLowerCase().split('.').pop();

const checkFileExt = (fileName, extensions = ['jpg', 'png', 'pdf', 'gif']) => {
  const ext = fileExt(fileName);

  return extensions.includes(ext);
};

export const validateErrors = (fields, validationFunction, dependencies = {}) => {
  const errors = Object.entries(fields).reduce((acc, [fieldName, fieldData]) => {
    const error = validationFunction(fieldName, fieldData, dependencies);
    return error ? { ...acc, [fieldName]: error } : acc;
  }, {});
  return Object.keys(errors).length > 0 && errors;
};

export const newValidateErrors = (fields, validationFunction, dependencies = {}) => {
  const errors = Object.entries(fields).reduce((acc, [fieldName, fieldData]) => {
    const error = validationFunction(fieldName, fieldData, dependencies);
    return { ...acc, [fieldName]: error || null };
  }, {});
  return Object.keys(errors).length > 0 && errors;
};

export const getErrorKeys = (err) => {
  return (
    Object.keys(Object.entries(err).filter(([, value]) => value)
      .reduce((acc, [key, value]) => {
        return { ...acc, [key]: value };
      }, {}))
  );
};

export const validateProjectInfo = (field, value, dependencies = {}) => {
  let error;
  if (field === 'type') {
    if (!value) {
      error = 'isTypeRequired';
    }
  }
  if (field === 'client' && dependencies.isClient) {
    if (!value) {
      error = 'isClientRequired';
    }
  }
  if (field === 'client_company') {
    if (!value) {
      error = 'isClientCompanyRequired';
    }
  }
  if (field === 'client_product' && dependencies.isClient) {
    if (!value) {
      error = 'isProductRequired';
    }
  }
  if (field === 'contract_type' && dependencies.isClient) {
    if (!value) {
      error = 'isContractTypeRequired';
    }
  }
  return error;
};

export const validateTimesheetLogTimeForm = (field, value) => {
  let error;
  if (field === 'user') {
    if (!value) {
      error = 'isEmployeeRequired';
    }
  }
  if (field === 'datepicker') {
    if (!value) {
      error = 'isDateRequired';
    }
  }
  if (field === 'project') {
    if (!value) {
      error = 'isProjectRequired';
    }
  }
  if (field === 'ticket') {
    if (!value) {
      error = 'isTicketRequired';
    }
  }
  if (field === 'time') {
    if (!value) {
      error = 'isTimeRequired';
    } else if (!isLogTime(value)) {
      error = 'isTimeNotValid';
    }
  }
  return error;
};

export const validateProjectCreate = (field, value, dependencies = {}) => {
  let error;
  if (field === 'name') {
    if (!value) {
      error = 'isNameRequired';
    }
  }
  if (field === 'key') {
    if (!value) {
      error = 'isKeyRequired';
    }
  }
  if (field === 'client' && dependencies.isActive) {
    if (!value) {
      error = 'isClientRequired';
    }
  }
  if (field === 'product' && dependencies.isActive) {
    if (!value) {
      error = 'isProductRequired';
    }
  }
  if (field === 'manager') {
    if (!value) {
      error = 'isManagerRequired';
    }
  }
  if (field === 'type') {
    if (!value) {
      error = 'isTypeRequired';
    }
  }
  if (field === 'contract_type' && dependencies.isActive) {
    if (!value) {
      error = 'isContractTypeRequired';
    }
  }
  return error;
};

export const validateAddProjectItem = (field, value, type = '') => {
  let error;
  if (field === 'name') {
    if (!value) {
      error = 'isProjectItemNameRequired';
    }
  }
  if (field === 'type') {
    if (!value) {
      error = 'isProjectItemTypeRequired';
    }
  }

  if (field === 'rate_type') {
    if (!value) {
      error = 'isProjectItemRateTypeRequired';
    }
  }
  if (field === 'rate') {
    if (!value) {
      error = 'isProjectItemRateRequired';
    } else if (!isDouble(value)) {
      error = 'isProjectItemRateNotDouble';
    } else if (!maxDecimalPart(value, 2)) {
      error = 'isProjectItemRateMaxDecimal';
    }
  }
  if (type === PROJECT_ITEM_TM_TYPE) {
    if (field === 'overtime_hourly_rate') {
      if (!value) {
        error = 'isProjectItemHourlyRateRequired';
      } else if (!isDouble(value)) {
        error = 'isProjectItemHourlyRateNotDouble';
      } else if (!maxDecimalPart(value, 2)) {
        error = 'isProjectItemHourlyRateMaxDecimal';
      }
    }
  }
  if (type === PROJECT_ITEM_FIXED_PRICE_TYPE) {
    if (field === 'planned_billed_time') {
      if (!value) {
        error = 'isProjectItemBilledTimeRequired';
      } else if (!maxDecimalPart(value, 2)) {
        error = 'isProjectItemRateMaxDecimal';
      }
    }
  }
  if (field === 'currency') {
    if (!value) {
      error = 'isProjectItemCurrencyRequired';
    }
  }
  return error;
};

export const validateAddPeopleOrGroupModal = (field, value, isGroup) => {
  let error;
  if (isGroup) {
    if (field === 'group') {
      if (!value) {
        error = 'isGroupRequired';
      }
    }
  } else if (field === 'userName') {
    if (!value) {
      error = 'isNameRequired';
    }
  }

  if (field === 'role') {
    if (!value) {
      error = 'isRoleRequired';
    }
  }
  return error;
};

export const validateCreateWorklogPermission = (field, value) => {
  let error;
  if (field === 'employee') {
    if (!value) {
      error = 'isEmployeeRequired';
    }
  }
  if (field === 'project') {
    if (!value) {
      error = 'isProjectRequired';
    }
  }
  if (field === 'period') {
    if (!value) {
      error = 'isPeriodRequired';
    }
  }
  if (field === 'duration') {
    if (!value) {
      error = 'isDurationRequired';
    } else if (!isLogTime(value)) {
      error = 'isDurationValid';
    }
  }
  if (field === 'reason') {
    if (!value) {
      error = 'isReasonRequired';
    }
  }
  return error;
};

export const validateAddTicket = (field, value) => {
  let error;
  if (field === 'summary') {
    if (!value) {
      error = 'isSummaryRequired';
    }
  }
  return error;
};

export const validateAddOrEditHoliday = (field, value, dependencies = {}) => {
  let error;
  if (field === 'name') {
    if (!value) {
      error = 'isNameRequired';
    }
  }
  if (field === 'date') {
    if (!value) {
      error = 'isDateRequired';
    }
  }
  if (field === 'startYear' && dependencies.type === 'fixed') {
    if (!value) {
      error = 'isStartYearRequired';
    } else if (!isInteger(value)) {
      error = 'isStartYearNotValid';
    }
  }
  if (field === 'endYear' && value) {
    if (!isInteger(value)) {
      error = 'isEndYearNotValid';
    }
  }
  return error;
};

export const validateRoleFrom = (field, value) => {
  let error;
  if (field === 'name') {
    if (!value) {
      error = 'isNameRequired';
    }
  }
  return error;
};

export const validateEditManager = (field, value) => {
  let error;
  if (field === 'name') {
    if (!value) {
      error = 'isNameRequired';
    }
  }
  if (field === 'manager') {
    if (!value) {
      error = 'isManagerRequired';
    }
  }
  if (field === 'date') {
    if (!value) {
      error = 'isDateRequired';
    }
  }
  return error;
};

export const validateInvoiceCustomItem = (field, value) => {
  let error;
  if (field === 'company') {
    if (!value) {
      error = 'isCompanyRequired';
    }
  }
  if (field === 'paymentMethod') {
    if (!value) {
      error = 'isPaymentMethodRequired';
    }
  }
  if (field === 'invoiceName') {
    if (!value) {
      error = 'isInvoiceNameRequired';
    } else if (!isMaxLength(value)) {
      error = 'isInvoiceNameLength';
    }
  }
  if (field === 'itemName') {
    if (!value) {
      error = 'isItemNameRequired';
    } else if (!isMaxLength(value)) {
      error = 'isItemNameLength';
    }
  }
  if (field === 'amount') {
    if (!value) {
      error = 'isAmountRequired';
    } else if (!isDouble(value)) {
      error = 'isAmountNotDouble';
    } else if (!maxDecimalPart(value, 2) || !maxIntegerPart(value, 18)) {
      error = 'isAmountMaxDecimal';
    }
  }
  if (field === 'currency') {
    if (!value) {
      error = 'isCurrencyRequired';
    }
  }
  if (field === 'category') {
    if (!value) {
      error = 'isCategoryRequired';
    }
  }
  return error;
};

export const validateCommentModal = (field, value, isEdit) => {
  let error;

  if (field === 'comment_json' && !isEdit && !value) {
    error = 'isCommentRequired';
  }

  if (field === 'editCommentJson' && isEdit && !value) {
    error = 'isCommentRequired';
  }

  if (field === 'problem') {
    if (!isMaxLength(value, 255)) {
      error = 'isProblemLength';
    }
  }
  return error;
};

export const validateCreateInvoiceModal = (field, value) => {
  let error;
  if (field === 'category_id' && !value) {
    error = 'isCategoryRequired';
  }
  if (field === 'name' && !value) {
    error = 'isNameRequired';
  }
  if (field === 'amount') {
    if (!value) {
      error = 'isAmountRequired';
    } else if (!isDouble(value)) {
      error = 'isAmountNotDouble';
    } else if (!maxDecimalPart(value, 2) || !maxIntegerPart(value, 18)) {
      error = 'isAmountMaxDecimal';
    }
  }
  return error;
};

export const validateAddOrEditFOP = (field, value, dependencies = {}) => {
  let error;
  if (field === 'user' && !value) {
    error = 'isEmployeeRequired';
  }

  if (field === 'month' && !value) {
    error = 'isMonthRequired';
  }

  if (dependencies.is_fop) {
    if (field === 'name' && !value) {
      error = 'isNameRequired';
    }

    if (field === 'name_ukr' && !value) {
      error = 'isNameUkrRequired';
    }

    if (field === 'account_uah' && !value) {
      error = 'isAccountUahRequired';
    }

    if (field === 'fop_address' && !value) {
      error = 'isFopAddressRequired';
    }

    if (field === 'fop_address_ukr' && !value) {
      error = 'isFopAddressUkrRequired';
    }

    if (field === 'bank_swift' && !value) {
      error = 'isBankSwiftRequired';
    }

    if (field === 'bank_name' && !value) {
      error = 'isBankNameRequired';
    }

    if (field === 'agreement_type' && !value) {
      error = 'isAgreementTypeRequired';
    }

    if (field === 'fop_bank_account_cost_uah') {
      if (!value) {
        error = 'isBankCostRequired';
      } else if (!isDouble(value)) {
        error = 'isBankCostNotDouble';
      } else if (!maxDecimalPart(value, 2) || !maxIntegerPart(value, 8)) {
        error = 'isBankCostMaxDecimal';
      }
    }
  }

  if (field === 'en' && value) {
    if (parseFloat(value) > 1 || parseFloat(value) < 0) {
      error = 'isEnMoreOrLess';
    } else if (!isDouble(value)) {
      error = 'isFopEnValid';
    }
  }
  if (value && field === 'tax_id_number') {
    if (!isInteger(value)) {
      error = 'isTaxNumberNotInteger';
    } else if (!isMaxLength(value, 10)) {
      error = 'isTaxNumberLength';
    }
  }
  if (field === 'passport_number' && dependencies.isPassportReq) {
    if (!value) {
      error = 'isPassportNumberRequired';
    }
  }
  return error;
};

export const validateAddMembersToRole = (field, value) => {
  let error;
  if (field === 'members' && value.length === 0) {
    error = 'isMembersRequired';
  }
  return error;
};

export const validateEmployeesReport = (field, value) => {
  let error;
  if (field === 'start_month') {
    if (!value) {
      error = 'isDateRequired';
    } else if (!isFormatDateDDMonYYYY(value) && !isFormatDateYYYYMMDD(value)) {
      error = 'isDateInputRequired';
    }
  }
  return error;
};

export const validateSalaryBaseModal = (field, value, dependencies = {}) => {
  let error;
  if (field === 'employee') {
    if (!value) {
      error = 'isEmployeeRequired';
    }
  }
  if (field === 'department' && dependencies?.isDepartment) {
    if (!value) {
      error = 'isDepartmentRequired';
    }
  }
  if (field === 'rate_type') {
    if (!value) {
      error = 'isRateTypeRequired';
    }
  }
  if (field === 'date') {
    if (!value) {
      error = 'isDateRequired';
    }
  }
  if (field === 'start_date') {
    if (!value) {
      error = 'isDateRequired';
    }
  }
  if (field === 'rate') {
    if (!value && value !== 0) {
      error = 'isRateRequired';
    } else if (!isDouble(value)) {
      error = 'isRateValid';
    } else if (!maxDecimalPart(value, 2) || !maxIntegerPart(value, 18)) {
      error = 'isRateMaxDecimal';
    }
  }
  if (field === 'rate_bench' && value) {
    if (!isDouble(value)) {
      error = 'isRateBenchValid';
    } else if (!maxDecimalPart(value, 2) || !maxIntegerPart(value, 18)) {
      error = 'isRateBenchDecimal';
    } else if (+dependencies < value || value < 0) {
      error = 'isRateBenchBiggestRate';
    }
  }
  if (field === 'overtime_hourly_rate') {
    if (!value && value !== 0) {
      error = 'isOvertimeHourlyRateRequired';
    } else if (!isDouble(value)) {
      error = 'isOvertimeHourlyRateValid';
    } else if (!maxDecimalPart(value, 2) || !maxIntegerPart(value, 18)) {
      error = 'isOvertimeHourlyRateMaxDecimal';
    }
  }
  if (field === 'preferred_payment_method') {
    if (!value) {
      error = 'isPreferredPaymentMethodRequired';
    }
  }
  if (field === 'card_number' && dependencies?.isPreferredPaymentMethodCard) {
    if (!value) {
      error = 'isCardNumberRequired';
    } else if (value.length > 0 && !isCardNumber(+value.replace(/ /g, ''))) {
      error = 'isNotValid';
    }
  }
  if (field === 'logging_mode' && !value) {
    error = 'isLoggingModeRequired';
  }
  if (field === 'vacation_factor') {
    if (!value) {
      error = 'isVacationFactorRequired';
    } else if (!isDouble(value)) {
      error = 'isVacationFactorValid';
    }
  }
  if (field === 'sickness_leave_factor') {
    if (!value) {
      error = 'isSicknessLeaveFactorRequired';
    } else if (!isDouble(value)) {
      error = 'isSicknessLeaveFactorValid';
    }
  }
  return error;
};

export const validateSalaryBonusModal = (field, value, dependencies = {}) => {
  let error;
  if (field === 'employee') {
    if (!value) {
      error = 'isEmployeeRequired';
    }
  }
  if (field === 'month') {
    if (!value) {
      error = 'isMonthRequired';
    }
  }
  if (field === 'amount') {
    if (!value) {
      error = 'isAmountRequired';
    } else if (!isDouble(value)) {
      error = 'isAmountNotDouble';
    } else if (!maxDecimalPart(value, 2) || !maxIntegerPart(value, 18)) {
      error = 'isAmountMaxDecimal';
    }
  }
  if (field === 'category') {
    if (!value) {
      error = 'isCategoryRequired';
    }
  }
  if (field === 'client' && dependencies.isClientReq) {
    if (!value) {
      error = 'isClientRequired';
    }
  }
  if (field === 'project' && dependencies.isProjectReq) {
    if (!value) {
      error = 'isProjectRequired';
    }
  }
  if (field === 'user' && dependencies.isUserReq) {
    if (!value) {
      error = 'isGpnlUserRequired';
    }
  }
  if (field === 'comment') {
    if (!value) {
      error = 'isCommentRequired';
    }
  }
  return error;
};

export const validateFinanceRateForm = (field, value) => {
  let error;
  if (field === 'month' && !value) {
    error = 'isMonthRequired';
  }
  if (field === 'bankRate') {
    if (!value) {
      error = 'isBankRateRequired';
    } else if (!isDouble(value)) {
      error = 'isBankRateValid';
    } else if (!maxDecimalPart(value, 2) || !maxIntegerPart(value, 18)) {
      error = 'isBankRateMaxDecimal';
    }
  }
  if (field === 'cardRate') {
    if (!value) {
      error = 'isCardRateRequired';
    } else if (!isDouble(value)) {
      error = 'isCardRateValid';
    } else if (!maxDecimalPart(value, 2) || !maxIntegerPart(value, 18)) {
      error = 'isCardRateMaxDecimal';
    }
  }
  if (field === 'fopRate') {
    if (!value) {
      error = 'isFopRateRequired';
    } else if (!isDouble(value)) {
      error = 'isFopRateValid';
    } else if (!maxDecimalPart(value, 2) || !maxIntegerPart(value, 18)) {
      error = 'isFopRateMaxDecimal';
    }
  }
  return error;
};

export const validateFopInfoForm = (field, value) => {
  let error;
  if (field === 'month') {
    if (!value) {
      error = 'isMonthRequired';
    }
  }
  if (field === 'fopRentUah') {
    if (!value) {
      error = 'isRentUahRequired';
    } else if (!isDouble(value)) {
      error = 'isRentUahValid';
    } else if (!maxDecimalPart(value, 2) || !maxIntegerPart(value, 18)) {
      error = 'isRentUahMaxDecimal';
    }
  }
  if (field === 'fopEsvUah') {
    if (!value) {
      error = 'isEsvUahRequired';
    } else if (!isDouble(value)) {
      error = 'isEsvUahValid';
    } else if (!maxDecimalPart(value, 2) || !maxIntegerPart(value, 18)) {
      error = 'isEsvUahMaxDecimal';
    }
  }
  if (field === 'fopEn') {
    if (!value) {
      error = 'isFopEnRequired';
    } else if (parseFloat(value) > 1 || parseFloat(value) < 0) {
      error = 'isFopEnMoreOrLess';
    } else if (!isDouble(value)) {
      error = 'isFopEnValid';
    } else if (!maxDecimalPart(value, 2) || !maxIntegerPart(value, 1)) {
      error = 'isFopEnMaxDecimal';
    }
  }
  return error;
};

export const validateAssetsModal = (field, value, dependencies) => {
  let error;
  if (field === 'category') {
    if (!value) {
      error = 'isCategoryRequired';
    }
  }
  if (field === 'inventory_number' && dependencies?.isValidateInventory) {
    if (!value) {
      error = 'isInventoryNumberRequired';
    }
  }
  if (field === 'ean_number' && value) {
    if (!isInteger(value)) {
      error = 'isEanNumberInteger';
    } else if (!maxIntegerPart(value, 14)) {
      error = 'isEanNumberMaxLength';
    } else if (maxIntegerPart(value, 10)) {
      error = 'isEanNumberMinLength';
    }
  }
  if (field === 'name') {
    if (!value) {
      error = 'isNameRequired';
    }
  }
  if (field === 'comment' && dependencies?.isValidateComment && dependencies?.isCommentRequired) {
    if (!value) {
      error = 'isCommentRequired';
    }
  }
  if (field === 'amount') {
    if (value) {
      if (!isDouble(value)) {
        error = 'isAmountNotDouble';
      }
    }
  }
  if (field === 'currency' && dependencies?.isAmountExist) {
    if (!value) {
      error = 'isCurrencyRequired';
    }
  }

  if (field === 'currency_rate') {
    if (dependencies?.isCurrencyExist && !value) {
      error = 'isCurrencyRateRequired';
    }

    if (value) {
      if (!isDouble(value)) {
        error = 'isCurrencyRateNotDouble';
      } else if (!maxDecimalPart(value, 2) || !maxIntegerPart(value, 20)) {
        error = 'isCurrencyRateMaxDecimal';
      }
    }
  }

  if (field === 'department' && dependencies?.isDepartmentExist) {
    if (!value) {
      error = 'isDepartmentRequired';
    }
  }

  return error;
};

export const validateProjectDocModal = (field, value) => {
  let error;
  if (field === 'name') {
    if (!value) {
      error = 'isNameRequired';
    }
  }

  if (field === 'type') {
    if (!value) {
      error = 'isTypeRequired';
    }
  }
  if (field === 'link') {
    if (!value) {
      error = 'isLinkRequired';
    }
  }
  return error;
};

export const validateSalaryCorrectionModal = (field, value) => {
  let error;
  if (field === 'user') {
    if (!value) {
      error = 'isEmployeeRequired';
    }
  }
  if (field === 'month') {
    if (!value) {
      error = 'isMonthRequired';
    }
  }
  if (field === 'amount') {
    if (!value) {
      error = 'isAmountRequired';
    } else if (!/^[-]?\d+(\.\d+)?$/i.test(value)) {
      error = 'isAmountNotDouble';
    } else if (!maxDecimalPart(value, 2) || !maxIntegerPart(value, 18)) {
      error = 'isAmountMaxDecimal';
    }
  }
  if (field === 'amount_rate' && value) {
    if (!isDouble(value)) {
      error = 'isAmountRateNotDouble';
    } else if (!maxDecimalPart(value, 2) || !maxIntegerPart(value, 18)) {
      error = 'isAmountRateMaxDecimal';
    }
  }
  if (field === 'amount_uah' && value) {
    if (!/^[-]?\d+(\.\d+)?$/i.test(value)) {
      error = 'isAmountUahNotDouble';
    } else if (!maxDecimalPart(value, 2) || !maxIntegerPart(value, 18)) {
      error = 'isAmountUahMaxDecimal';
    }
  }
  if (field === 'type') {
    if (!value) {
      error = 'isTypeRequired';
    }
  }
  if (field === 'comment') {
    if (!value) {
      error = 'isCommentRequired';
    }
  }
  return error;
};

export const validateCreateExpenseReport = (field, value) => {
  let error;
  if (field === 'salaryCorrectionType') {
    if (!value) {
      error = 'isTypeRequired';
    }
  }
  if (field === 'date') {
    if (!value) {
      error = 'isDateRequired';
    }
  }
  if (field === 'amount') {
    if (!value) {
      error = 'isAmountRequired';
    } else if (!isDouble(value)) {
      error = 'isAmountNotDouble';
    }
  }
  if (field === 'currency') {
    if (!value) {
      error = 'isCurrencyRequired';
    }
  }
  if (field === 'userComment') {
    if (!value) {
      error = 'isCommentRequired';
    }
  }
  return error;
};

export const validateApproveExpenseReport = (field, value) => {
  let error;
  if (field === 'approvedAmount') {
    if (!value) {
      error = 'isAmountRequired';
    } else if (!isDouble(value)) {
      error = 'isAmountNotDouble';
    }
  }
  if (field === 'currencyRate') {
    if (!value) {
      error = 'isRateRequired';
    } else if (!isDouble(value)) {
      error = 'isRateNotDouble';
    }
  }
  if (field === 'salaryCorrectionType') {
    if (!value) {
      error = 'isTypeRequired';
    }
  }
  return error;
};

export const validateSystemsDepartmentsForm = (field, value, dependencies = {}) => {
  let error;

  if (field === 'name') {
    if (!value) {
      error = 'isNameRequired';
    }
  }

  if ((field === 'loRate' || field === 'hiRate') && dependencies.isBhrSchema) {
    if (!value) {
      error = 'isRateRequired';
    } else if (!isDouble(value)) {
      error = 'isRateValid';
    } else if (!maxDecimalPart(value, 2) || !maxIntegerPart(value, 18)) {
      error = 'isRateMaxDecimal';
    }
  }

  if (field === 'percent' && dependencies.isGmpSchema) {
    if (!value) {
      error = 'isPercentRequired';
    } else if (!isDouble(value)) {
      error = 'isPercentValid';
    } else if (value > 100) {
      error = 'isPercentMore100';
    }
  }

  if (field === 'description') {
    if (value && value.length > MAX_SHORT_DESCRIPTION_LENGTH) {
      error = 'isDescriptionLength';
    }
  }

  return error;
};

export const validateAssetsCategoriesForm = (field, value) => {
  let error;
  if (field === 'name') {
    if (!value) {
      error = 'isNameRequired';
    }
  }
  if (field === 'key') {
    if (!value) {
      error = 'isKeyRequired';
    }
  }
  return error;
};

export const validateSalaryBonusCategoryForm = (field, value, dependencies = {}) => {
  let error;
  if (field === 'name') {
    if (!value) {
      error = 'isNameRequired';
    }
  }
  if (field === 'xeroId' && dependencies.isXeroReq) {
    if (!value) {
      error = 'isXeroIdRequired';
    }
  }
  return error;
};

export const validateSalaryCorrectionTypeForm = (field, value) => {
  let error;
  if (field === 'name') {
    if (!value) {
      error = 'isTypeRequired';
    }
  }
  if (field === 'xeroId') {
    if (!value) {
      error = 'isXeroIdRequired';
    }
  }
  return error;
};

export const validateInvoiceItemCategoryForm = (field, value) => {
  let error;
  if (field === 'name') {
    if (!value) {
      error = 'isNameRequired';
    }
  }
  if (field === 'xeroId') {
    if (!value) {
      error = 'isXeroIdRequired';
    }
  }
  return error;
};

export const validateTechnologiesTreeModal = (field, value) => {
  let error;
  if (field === 'name') {
    if (!value) {
      error = 'isNameRequired';
    }
  }
  if (field === 'description') {
    if (!isMaxLength(value, 255)) {
      error = 'isDescriptionLength';
    }
  }
  if (field === 'link') {
    const regexp = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
    if (value.length && !regexp.test(value)) {
      error = 'isLinkNotValid';
    }
  }
  return error;
};

export const validateSystemsTrackersModal = (field, value, dependencies) => {
  let error;

  if (field === 'trackerName') {
    if (!value) {
      error = 'isNameRequired';
    }
  }

  if (field === 'email') {
    if (!value) {
      error = 'isEmailRequired';
    }
  }

  if (field === 'url') {
    if (!value) {
      error = 'isUrlRequired';
    }
  }

  if (field === 'apiToken') {
    if (!value) {
      error = 'isApiTokenRequired';
    }
  }

  if (field === 'tempoApiToken') {
    if (!value && dependencies.tempoApiTokenRequired) {
      error = 'isTempoApiTokenRequired';
    }
  }
  return error;
};

export const validateSkillsModal = (field, value) => {
  let error;

  if (field === 'name') {
    if (!value) {
      error = 'isNameRequired';
    }
  }
  if (field === 'usageLevelName') {
    if (!value) {
      error = 'isUsageLevelNameRequired';
    }
  }

  if (field === 'lastUsage') {
    if (!value) {
      error = 'isLastUsageRequired';
    }

    if (value) {
      if (!isInteger(value)) {
        error = 'isLastUsageInteger';
      } else if (value > 2100) {
        error = 'isLastUsageValid';
      } else if (value < 2000) {
        error = 'isLastUsageValid';
      }
    }
  }
  return error;
};

export const validateClientCompaniesModal = (field, value) => {
  let error;
  if (field === 'name') {
    if (!value) {
      error = 'isNameRequired';
    }
  }
  return error;
};

export const validateHelpCenter = (field, value) => {
  let error;
  if (field === 'link') {
    if (!value) {
      error = 'isLinkRequired';
    }
  }
  return error;
};

export const validateClientAgreement = (field, value, dependencies) => {
  let error;
  if (field === 'name') {
    if (!value) {
      error = 'isNameRequired';
    }
  }
  if (field === 'clientCompanies') {
    if (!value || value?.length === 0) {
      error = 'isClientCompanyRequired';
    }
  }
  if (field === 'startDate') {
    if (!value) {
      error = 'isStartDateRequired';
    }
  }
  if (field === 'currency' && dependencies.isServiceAgreement) {
    if (!value) {
      error = 'isCurrencyRequired';
    }
  }
  if (field === 'paymentMethod' && dependencies.isServiceAgreement) {
    if (!value) {
      error = 'isPaymentMethodRequired';
    }
  }
  if (field === 'pmCommentText' && dependencies.paymentMethod === 'other' && dependencies.isServiceAgreement) {
    if (!value) {
      error = 'isPaymentMethodCommentRequired';
    }
  }
  if (field === 'ourCompany') {
    if (!value) {
      error = 'isOurCompanyRequired';
    }
  }
  if (field === 'documentFile' && value && dependencies.isUpload) {
    if (!isSizeLess50Mb(value)) {
      error = 'isFileGreater';
    } else if (!checkFileExt(value.file.name)) {
      error = 'isNotExtFileType';
    }
  }
  if (field === 'ourCompanyOtherText' && dependencies.ourCompany === 'other') {
    if (!value) {
      error = 'isOurCompanyTextRequired';
    } else if (!isMaxLength(value)) {
      error = 'isOurCompanyTextLength';
    }
  }
  if (field === 'nonCompeteTerms' && dependencies.nonCompete) {
    if (!value) {
      error = 'isNonCompeteTermsRequired';
    }
  }
  return error;
};

export const validateCVGeneratorForm = (field, value, dependencies) => {
  let error;

  if (field === 'summary' || field === 'experience' || field === 'education') {
    if (field === 'summary' && value && value.length > 400) {
      error = 'professionalSummaryToLong';
    }

    if (value && Object.keys(dependencies).length > 0) {
      const findProjectNDA = getProjectUnderNDA(value, dependencies.projectNamesNDA);

      if (findProjectNDA) {
        error = findProjectNDA;
      }
    }
  }

  if (field === 'githubLink') {
    if (value && !isUrl(value)) {
      error = 'isLinkNotValid';
    }
  }
  if (field === 'name') {
    if (!value) {
      error = 'isNameRequired';
    }
  }
  return error;
};

export const validateCVGeneratorProjectForm = (field, value, dependencies) => {
  let error;
  if (field === 'name') {
    if (!value) {
      error = 'isNameRequired';
    }
  }
  if (field === 'short_description') {
    if (value && value.length > 255) {
      error = 'descriptionNameLength';
    }
  }
  if (field === 'link') {
    if (value && !isUrl(value)) {
      error = 'isLinkNotValid';
    }
  }

  if (field === 'name' || field === 'short_description' || field === 'link') {
    if (value && Object.keys(dependencies).length > 0) {
      const findProjectNDA = getProjectUnderNDA(value, dependencies.projectNamesNDA);

      if (findProjectNDA) {
        error = 'removeTheName';
      }
    }
  }

  return error;
};

export const validateAgreementDocument = (field, value, dependencies) => {
  let error;
  if (field === 'name') {
    if (!value) {
      error = 'isNameRequired';
    }
  }
  if (field === 'date') {
    if (!value) {
      error = 'isDateRequired';
    }
  }
  if (field === 'documentLink') {
    if (value) {
      if (!isUrl(value)) {
        error = 'isDocumentLinkNotValid';
      }
    }
  }
  if (field === 'documentFile' && value && dependencies.isUpload) {
    if (!isSizeLess50Mb(value)) {
      error = 'isFileGreater';
    }
  }
  return error;
};

export const validateClientProduct = (field, value, dependencies) => {
  let error;

  if (field === 'name') {
    if (!value) {
      error = 'isNameRequired';
    }
  }
  if (field === 'dealLink' && value) {
    if (!isUrl(value) || !value.includes('pipedrive')) {
      error = 'isPipedriveLinkNotValid';
    }
  }
  if (field === 'dealBudget' && value) {
    if (!isDouble(value)) {
      error = 'isBudgetValid';
    }
  }

  if (field === 'dealCurrency' && !value && dependencies?.isDealBudget) {
    error = 'isCurrencyRequired';
  }

  return error;
};

export const validateAgreementService = (field, value, dependencies) => {
  let error;
  if (field === 'name') {
    if (!value) {
      error = 'isNameRequired';
    }
  }
  if (field === 'startDate') {
    if (!value) {
      error = 'isStartDateRequired';
    }
  }
  if (field === 'department') {
    if (!value) {
      error = 'isDepartmentRequired';
    }
  }
  if (field === 'rate') {
    if (!value) {
      error = 'isRateRequired';
    } else if (!isDouble(value)) {
      error = 'isRateValid';
    } else if (!maxDecimalPart(value, 2) || !maxIntegerPart(value, 18)) {
      error = 'isRateMaxDecimal';
    }
  }
  if (field === 'rateType') {
    if (!value) {
      error = 'isRateTypeRequired';
    }
  }
  if (field === 'overtimeRate' && dependencies.rateType !== 'fixed') {
    if (!value) {
      error = 'isOvertimeHourlyRateRequired';
    }
  }
  return error;
};

export const validateMilestone = (field, value) => {
  let error;
  if (field === 'name') {
    if (!value || /^\s*$/.test(value)) {
      error = 'isNameRequired';
    }
  }
  if (field === 'startDate') {
    if (!value) {
      error = 'isStartDateRequired';
    }
  }
  if (field === 'dateFinishPlan') {
    if (!value) {
      error = 'isPlanedDateRequired';
    }
  }
  if (field === 'milestonePeriod') {
    if (value > 182 && value < 15000) {
      error = 'isMilestonePeriodMax';
    }
  }
  if (field === 'billingPeriod') {
    if (!value) {
      error = 'isBillingPeriodRequired';
    }
  }
  if (field === 'type') {
    if (!value) {
      error = 'isTypeRequired';
    }
  }
  return error;
};

export const validateMilestoneInfo = (field, value) => {
  let error;
  if (field === 'name') {
    if (!value) {
      error = 'isNameRequired';
    }
  }
  if (field === 'managerComment') {
    if (!value) {
      error = 'isCommentRequired';
    }
  }
  if (field === 'approvedDate') {
    if (!value) {
      error = 'isApprovedDateRequired';
    }
  }
  if (field === 'images') {
    if (value.length === 0) {
      error = 'isFileRequired';
    }
  }

  return error;
};

export const validateMilestoneItem = (field, value, dependencies = {}) => {
  let error;
  if (field === 'name') {
    if (!value) {
      error = 'isNameRequired';
    }
  }
  if (field === 'invoiceItemText') {
    if (!value) {
      error = 'isInvoiceItemTextRequired';
    }
  }
  if (field === 'startDate') {
    if (!value) {
      error = 'isStartDateRequired';
    }
  }
  if (field === 'endDate') {
    if (!value) {
      error = 'isEndDateRequired';
    }
  }
  if (field === 'service') {
    if (!value) {
      error = 'isServiceRequired';
    }
  }
  if (field === 'milestone') {
    if (!value) {
      error = 'isMilestoneRequired';
    }
  }
  if (field === 'plannedApprovedHours' && !dependencies.isSupportMilestone) {
    if (!value) {
      error = 'isPlannedApprovedHoursRequired';
    } else if (!isLogTimeWithoutStep(value)) {
      error = 'isTimeNotValid';
    }
  }
  if (field === 'workloadType' && !dependencies.isSupportMilestone) {
    if (!value) {
      error = 'isPlannedWorkloadTypeRequired';
    }
  }
  if (field === 'plannedBillability' && dependencies.isBillabilityReq) {
    if (!value && value !== 0) {
      error = 'isPlannedBillabilityRequired';
    }
  }
  if (field === 'plannedBilledHours' && dependencies.isBilledHoursReq) {
    if (value !== 0 && !value) {
      error = 'isPlannedBilledHoursRequired';
    }
  }
  if (field === 'users') {
    if (!value || value.length === 0) {
      error = 'isPlannedResourceRequired';
    }
  }
  return error;
};

export const validateProductProjectModal = (field, value) => {
  let error;
  if (field === 'project') {
    if (!value) {
      error = 'isProjectRequired';
    }
  }
  return error;
};

export const validateApplicationFrom = (field, value) => {
  let error;
  if (field === 'name') {
    if (!value) {
      error = 'isNameRequired';
    }
  }
  if (field === 'clientId') {
    if (!value) {
      error = 'isClientIdValid';
    }
  }
  return error;
};

export const validateSubscriptionFrom = (field, value, dependencies = {}) => {
  let error;

  if (dependencies.isReject) {
    if (field === 'rejectComment') {
      if (!value) {
        error = 'isCommentRequired';
      } else if (value && !isMaxLength(value, 150)) {
        error = 'isCommentLength';
      }
    }
  } else {
    if (field === 'subscriptionName') {
      if (!value) {
        error = 'isSubscriptionNameRequired';
      }
    }

    if (field === 'planName') {
      if (!value) {
        error = 'isPlanNameRequired';
      }
    }

    if (field === 'amount' && !dependencies.isReview) {
      if (!value) {
        error = 'isAmountRequired';
      } else if (!isDouble(value) || value <= 0) {
        error = 'isAmountNotDouble';
      }
    }

    if (field === 'approvedAmount' && (dependencies.isReview || dependencies.isApprove)) {
      if (!value) {
        error = 'isApprovedAmountRequired';
      } else if (!isDouble(value) || value <= 0) {
        error = 'isApprovedAmountNotDouble';
      }
    }

    if (field === 'currency') {
      if (!value) {
        error = 'isCurrencyRequired';
      }
    }

    if (field === 'currencyRate' && dependencies.isReview && !dependencies.isUSD) {
      if (!value) {
        error = 'isRateRequired';
      } else if (!isDouble(value)) {
        error = 'isRateNotDouble';
      }
    }

    if (field === 'department' && dependencies.isForDepartment) {
      if (Object.keys(value).length === 0) {
        error = 'isDepartmentRequired';
      }
    }

    if (field === 'date' && !dependencies.isForDepartment) {
      if (!value) {
        error = 'isOperationDateRequired';
      }
    }

    if (field === 'account' && value) {
      if (!isEmail(value)) {
        error = 'isAccountNotValid';
      }
    }

    if (field === 'url' && value) {
      if (!isUrl(value)) {
        error = 'isLinkNotValid';
      }
    }

    if (field === 'comment') {
      if (!value) {
        error = 'isCommentRequired';
      } else if (value && !isMaxLength(value, 150)) {
        error = 'isCommentLength';
      }
    }
  }

  return error;
};

export const validateTeachleadingForm = (field, value, dependencies = {}) => {
  let error;

  if ((!value || Object.keys(value).length === 0) && dependencies.isCreate) {
    if (field === 'department') {
      error = 'isDepartmentRequired';
    }

    if (field === 'lead') {
      error = 'isLeadRequired';
    }

    if (field === 'employee') {
      error = 'isEmployeeRequired';
    }

    if (field === 'project') {
      error = 'isProjectRequired';
    }
  }

  if (field === 'month' && dependencies.isCreate) {
    if (!value) {
      error = 'isMonthRequired';
    }
  }

  return error;
};

export const validateSystemsPosition = (field, value) => {
  let error;

  if (field === 'position') {
    if (!value) {
      error = 'isPositionRequired';
    }
  }
  if (field === 'abbreviation') {
    if (!value) {
      error = 'isAbbreviationRequired';
    }
  }
  if (field === 'urlToDescription' && value) {
    if (!isUrl(value)) {
      error = 'isLinkNotValid';
    }
  }

  return error;
};

export const validateSalaryPaymentBankAccountForm = (field, value) => {
  let error;
  if (field === 'name') {
    if (!value) {
      error = 'isNameRequired';
    }
  }
  if (field === 'xero_account_id') {
    if (!value) {
      error = 'isXeroAccountIdRequired';
    }
  }
  if (field === 'currency') {
    if (!value) {
      error = 'isCurrencyRequired';
    }
  }
  return error;
};

export const validatePaymentMethodsForm = (field, value) => {
  let error;
  if (field === 'name') {
    if (!value) {
      error = 'isNameRequired';
    }
  }
  if (field === 'xero_branding_theme_id') {
    if (!value) {
      error = 'isXeroBrandingThemeIdRequired';
    }
  }
  if (field === 'agreement_company') {
    if (!value) {
      error = 'isAgreementCompanyRequired';
    }
  }
  if (field === 'currencies') {
    if (!value || value.length === 0) {
      error = 'isCurrenciesRequired';
    }
  }
  return error;
};

export const validateSystemsPositionsInfo = (field, value) => {
  let error;

  if (field === 'employee') {
    if (!value) {
      error = 'isEmployeeRequired';
    }
  }
  if (field === 'startDate' && !value) {
    error = 'isStartDateRequired';
  }

  return error;
};

export const validateClientForm = (field, value) => {
  let error;

  if (field === 'comment') {
    if (value && value.length > MAX_SHORT_DESCRIPTION_LENGTH) {
      error = 'isCommentLengthLong';
    }
  }

  return error;
};

export const validateClientFeedback = (field, value) => {
  let error;

  if (field === 'link') {
    if (!value) {
      error = 'linkIsRequired';
    }

    if (value && !isUrl(value)) {
      error = 'isLinkNotValid';
    }
  }

  return error;
};

export const validateSalaryPayments = (field, value) => {
  let error;

  if (!value) {
    error = 'isBankAccountIsRequired';
  }

  return error;
};
