import {
  useCallback, useEffect, useMemo, useState
} from 'react';
import { shallowEqual } from 'react-redux';
import { useTranslation } from 'next-i18next';
import { Command } from 'cmdk';
import Link from 'next/link';
import * as Popover from '@radix-ui/react-popover';
import * as Tabs from '@radix-ui/react-tabs';
import * as HoverCard from '@radix-ui/react-hover-card';

import SearchIcon from '../../../components/Icons/SearchIcon';
import UsersIcon from '../../../components/Icons/UsersIcon';
import UsersMore from '../../../components/Icons/UsersMore';
import FolderCloseIcon from '../../../components/Icons/FolderCloseIcon';
import CaseIcon from '../../../components/Icons/CaseIcon';
import DocumentIcon from '../../../components/Icons/DocumentIcon';
import FactCheckIcon from '../../../components/Icons/FactCheckIcon';
import CloseIcon from '../../../components/Icons/Close';
import IconButton from '../../../components/Button/IconButton';
import GlobalSearchEmpty from './GlobalSearchEmpty';
import CheckSquareIcon from '../../../components/Icons/CheckSquareIcon';
import CompanyIcon from '../../../components/Icons/CompanyIcon';
import UserSearchCard from '../../../components/UserSearchCard/UserSearchCard';
import UserLink from '../../../components/UserLink/UserLink';
import ManagersWrap from '../../../components/Vacation/ManagersWrap';

import { useDispatch, useSelector } from '../../../hooks/reduxToolkit';
import { getGlobalSearch, resetState } from '../../../toolkitStore/globalSearch/slice';
import useDebounceFn from '../../../hooks/useDebounceFn';
import usePlatform from '../../../hooks/usePlatform';
import classNames from '../../../utils/classNames';
import { omit } from '../../../utils/objectHelpers';
import { isSearchItemInactive } from '../../../utils/helpers';
import { GLOBAL_SEARCH_TABS, GLOBAL_SEARCH_OPTIONS, GLOBAL_SEARCH_MIN_INPUT_LENGTH } from '../../../constants/common';

import styles from '../sass/GlobalSearch.module.scss';

const iconsMap = {
  [GLOBAL_SEARCH_OPTIONS.users]: <UsersIcon width={16} height={16} />,
  [GLOBAL_SEARCH_OPTIONS.position]: <UsersMore width={16} height={16} />,
  [GLOBAL_SEARCH_OPTIONS.projects]: <FolderCloseIcon width={16} height={16} />,
  [GLOBAL_SEARCH_OPTIONS.clients]: <CaseIcon width={16} height={16} />,
  [GLOBAL_SEARCH_OPTIONS.invoices]: <DocumentIcon width={16} height={16} />,
  [GLOBAL_SEARCH_OPTIONS.project_tickets]: <CheckSquareIcon width={16} height={16} />,
  [GLOBAL_SEARCH_OPTIONS.client_companies]: <CompanyIcon width={16} height={16} />,
  [GLOBAL_SEARCH_OPTIONS.client_products]: <FactCheckIcon width={16} height={16} fill="currentColor" />,
};

const linksMap = {
  [GLOBAL_SEARCH_OPTIONS.users]: (id) => `/employees/${id}`,
  [GLOBAL_SEARCH_OPTIONS.position]: () => '/systems/company-positions',
  [GLOBAL_SEARCH_OPTIONS.projects]: (id) => `/projects/${id}`,
  [GLOBAL_SEARCH_OPTIONS.clients]: (id) => `/clients/${id}`,
  [GLOBAL_SEARCH_OPTIONS.invoices]: (id) => `/invoices/${id}`,
  [GLOBAL_SEARCH_OPTIONS.project_tickets]: (projectId, ticketId) => `/projects/${projectId}/tickets/${ticketId}`,
  [GLOBAL_SEARCH_OPTIONS.client_companies]: (clientId, companyId) => `/clients/${clientId}/companies/${companyId}`,
  [GLOBAL_SEARCH_OPTIONS.client_products]: (clientId, productId) => `/clients/${clientId}/products/${productId}`,
};

const GlobalSearch = () => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [tab, setTab] = useState(GLOBAL_SEARCH_OPTIONS.all);

  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  const { isMac } = usePlatform();

  const globalSearchData = useSelector(({ globalSearch }) => globalSearch.data || [], shallowEqual);

  const { run: debouncedGlobalSearch } = useDebounceFn((value) => {
    if (value.length >= GLOBAL_SEARCH_MIN_INPUT_LENGTH) {
      dispatch(getGlobalSearch({ search: value }));
    } else if (!value) {
      dispatch(resetState());
    }
  });

  const parsedData = useMemo(() => {
    return {
      [GLOBAL_SEARCH_OPTIONS.all]: Object.values(globalSearchData).reduce((acc, item) => [...acc, ...item], []),
      [GLOBAL_SEARCH_OPTIONS.users]: globalSearchData?.users || [],
      [GLOBAL_SEARCH_OPTIONS.position]: globalSearchData?.position || [],
      [GLOBAL_SEARCH_OPTIONS.projects]: globalSearchData?.projects || [],
      [GLOBAL_SEARCH_OPTIONS.clients]: globalSearchData?.clients || [],
      [GLOBAL_SEARCH_OPTIONS.other]: Object.values(
        omit(globalSearchData || {}, ['users', 'projects', 'clients', 'position'])
      ).reduce((acc, item) => [...acc, ...item], []),
    };
  }, [globalSearchData]);

  const renderLink = useCallback((item) => {
    const isInactive = isSearchItemInactive(item);
    const classes = classNames({ [styles.inactive]: isInactive });

    const map = {
      [GLOBAL_SEARCH_OPTIONS.project_tickets]: (
        <Link href={linksMap[item.type](item.project_id, item.id)} className={classes}>{item.key}</Link>
      ),
      [GLOBAL_SEARCH_OPTIONS.client_products]: (
        <Link href={linksMap[item.type](item.client_id, item.id)} className={classes}>{item.name}</Link>
      ),
      [GLOBAL_SEARCH_OPTIONS.client_companies]: (
        <Link href={linksMap[item.type](item.client_id, item.id)} className={classes}>{item.name}</Link>
      ),
      [GLOBAL_SEARCH_OPTIONS.position]: (
        <Link href={linksMap[item.type]()} className={classes}>{item.name}</Link>
      ),
      default: <Link href={linksMap[item.type](item.id)} className={classes}>{item.name}</Link>
    };

    return map[item.type] || map.default;
  }, []);

  const handleSearch = useCallback((value) => {
    setSearch(value);
    debouncedGlobalSearch(value);
  }, [debouncedGlobalSearch]);

  const handleClearSearch = () => {
    setSearch('');
    dispatch(resetState());
  };

  useEffect(() => {
    const down = (e) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpen((prevState) => !prevState);
      }
    };

    document.addEventListener('keydown', down);
    return () => document.removeEventListener('keydown', down);
  }, []);

  return (
    <div className={styles.wrapper}>
      <Popover.Root open={open} onOpenChange={setOpen} modal>
        <Popover.Trigger className={styles.trigger}>
          <SearchIcon width={16} height={16} />
          <span className={styles.triggerLabel}>{t('search')}</span>
          <div className={styles.shortcuts}>
            {isMac ? (
              <kbd className={classNames(styles.shortcutsItem, styles.shortcutsItemMac)}>⌘</kbd>
            ) : (
              <kbd className={styles.shortcutsItem}>Ctrl</kbd>
            )}
            <kbd className={styles.shortcutsItem}>K</kbd>
          </div>
        </Popover.Trigger>
        <Popover.Content className={styles.content} align="start" sideOffset={-42}>
          <Command shouldFilter={false}>
            <div className={styles.searchWrapper}>
              <span className={styles.searchIcon}>
                <SearchIcon />
              </span>
              <Command.Input value={search} onValueChange={handleSearch} />
              {search ? (
                <IconButton
                  size="sm"
                  variant="ghost"
                  icon={<CloseIcon />}
                  onClick={handleClearSearch}
                />
              ) : null}
            </div>

            <Tabs.Root defaultValue={tab} onValueChange={setTab}>
              <Tabs.List className={styles.tabsList}>
                {GLOBAL_SEARCH_TABS.map((item) => (
                  <Tabs.Trigger key={item} className={styles.tabsTrigger} value={item}>
                    <span className={styles.tabsTriggerLabel}>{t(item)}</span>
                    <span className={styles.tabsTriggerBadge}>{parsedData[item].length}</span>
                  </Tabs.Trigger>
                ))}
              </Tabs.List>

              <Command.List className={styles.list}>
                <GlobalSearchEmpty>No results found</GlobalSearchEmpty>
                {parsedData[tab].map((item) => (
                  <Command.Item className={styles.listItem} key={item.id}>
                    <span className={styles.listItemIcon}>
                      {iconsMap[item.type]}
                    </span>
                    <HoverCard.Root openDelay={300}>
                      <HoverCard.Trigger className={styles.link}>
                        {renderLink(item)}
                      </HoverCard.Trigger>
                      {item.type === GLOBAL_SEARCH_OPTIONS.users && (
                        <UserSearchCard
                          username={item.name}
                          avatar={item.photo48_link}
                          email={item.email}
                          delivery={item.department?.name}
                          positions={item.positions ? (item.positions.map(({ name }) => name)).join(', ') : ''}
                          experience={item.year_in_company ? t('yearsExp', {
                            number: item.year_in_company,
                          }) : ''}
                          onVacation={item.on_vacation}
                        />
                      )}
                      {item.type === GLOBAL_SEARCH_OPTIONS.position && (
                        <div>
                          {item.users.length === 1 ? (
                            <UserLink user={item.users[0]} />
                          ) : (
                            <ManagersWrap
                              managers={item.users.map((user) => ({ user }))}
                              maxToShow={3}
                              width={24}
                              height={24}
                            />
                          )}
                        </div>
                      )}
                    </HoverCard.Root>
                    <div className={styles.listItemWhere}>
                      <span>{t(`globalSearch.${item.type}`)}</span>
                    </div>
                  </Command.Item>
                ))}
              </Command.List>
            </Tabs.Root>
          </Command>
        </Popover.Content>
      </Popover.Root>
    </div>
  );
};

export default GlobalSearch;
