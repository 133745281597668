import PropTypes from 'prop-types';
import { Slot } from '@radix-ui/react-slot';
import classNames from '../../utils/classNames';
import BallPulse from '../Loaders/BallPulse';
import styles from './sass/Button.module.scss';

const Button = ({
  children,
  asChild,
  variant,
  color,
  size,
  type,
  isFullWidth,
  isLoading,
  isDisabled,
  onClick,
  className,
  ariaLabel,
  dataLocation,
  dataTextContent,
  isMinWidth,
  isTrashed
}) => {
  const {
    Comp,
    type: compType,
    child,
  } = asChild ? {
    Comp: Slot,
    type: undefined,
    child: children,
  } : {
    Comp: 'button',
    type,
    child: (
      <>
        {children}
        {isLoading ? <BallPulse className={styles.loader} /> : null}
      </>
    )
  };

  const classes = classNames(
    styles.btn,
    className,
    {
      [styles[size]]: size !== 'md',
      [styles[variant]]: variant,
      [styles[color]]: color,
      [styles.fullWidth]: isFullWidth,
      [styles.minWidth]: isMinWidth,
      [styles.trashed]: isTrashed,
    },
  );

  return (
    <Comp
      className={classes}
      type={compType}
      onClick={onClick}
      disabled={isDisabled || isLoading}
      aria-label={ariaLabel}
      data-location={dataLocation}
      data-text-content={dataTextContent}
    >
      {child}
    </Comp>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  dataTextContent: PropTypes.string,
  dataLocation: PropTypes.string,
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  asChild: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  variant: PropTypes.oneOf(['ghost', 'contain', 'outline', 'outlineGray']),
  color: PropTypes.oneOf([
    'primary', 'secondary', 'success', 'error', 'white', 'default', 'link', 'grey', 'blue', 'green', 'red'
  ]),
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  type: PropTypes.oneOf(['submit', 'button']),
  isMinWidth: PropTypes.bool,
  isTrashed: PropTypes.bool
};

Button.defaultProps = {
  dataTextContent: undefined,
  dataLocation: undefined,
  ariaLabel: undefined,
  onClick: undefined,
  className: undefined,
  asChild: false,
  isFullWidth: false,
  isLoading: false,
  isDisabled: false,
  variant: 'contain',
  color: 'primary',
  size: 'md',
  type: 'button',
  isMinWidth: false,
  isTrashed: false
};

export default Button;
