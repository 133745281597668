import PropTypes from 'prop-types';

const CaseIcon = ({ width, height, fill }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 8C22 6.89 21.11 6 20 6H16V4C16 2.89 15.11 2 14 2H10C8.89 2 8 2.89 8 4V6H4C2.89 6 2.01 6.89 2.01 8L2 19C2 20.11 2.89 21 4 21H20C21.11 21 22 20.11 22 19V8ZM14 6V4H10V6H14ZM4 8V19H20V8H4Z"
        fill={fill}
      />
    </svg>
  );
};

CaseIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fill: PropTypes.string,
};

CaseIcon.defaultProps = {
  width: 24,
  height: 24,
  fill: 'currentColor',
};

export default CaseIcon;
