import PropTypes from 'prop-types';

const FactCheckIcon = ({ width, height, fill }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_23987_6852)">
        <path fillRule="evenodd" clipRule="evenodd" d="M12.2499 1.76172H1.74988C1.028 1.76172 0.437378 2.37422 0.437378 3.12283V12.6506C0.437378 13.3992 1.028 14.0117 1.74988 14.0117H12.2499C12.9718 14.0117 13.5624 13.3992 13.5624 12.6506V3.12283C13.5624 2.37422 12.9718 1.76172 12.2499 1.76172ZM12.2499 12.6506H1.74988V3.12283H12.2499V12.6506Z" fill={fill} />
        <path fillRule="evenodd" clipRule="evenodd" d="M11.3225 6.96505L10.4942 6.13672L8.645 7.98589L7.8225 7.15755L7 7.98005L8.645 9.63672L11.3225 6.96505Z" fill={fill} />
        <path d="M5.83342 4.96973H2.91675V6.13639H5.83342V4.96973Z" fill={fill} />
        <path d="M5.83342 7.30371H2.91675V8.47038H5.83342V7.30371Z" fill={fill} />
        <path d="M5.83342 9.63672H2.91675V10.8034H5.83342V9.63672Z" fill={fill} />
      </g>
      <defs>
        <clipPath id="clip0_23987_6852">
          <rect width="14" height="14" fill="white" transform="translate(0 0.886719)" />
        </clipPath>
      </defs>
    </svg>
  );
};

FactCheckIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fill: PropTypes.string,
};

FactCheckIcon.defaultProps = {
  width: 14,
  height: 15,
  fill: '#0072FF',
};

export default FactCheckIcon;
