import PropTypes from 'prop-types';

const SubscriptionsIcon = ({ width, height, fill }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 14 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.42857 9.85H9.57143M4.42857 13.45H9.57143M4.42857 6.25H9.57143M1 2.65C1 2.41131 1.09031 2.18239
        1.25105 2.0136C1.4118 1.84482 1.62981 1.75 1.85714 1.75H12.1429C12.3702 1.75 12.5882 1.84482 12.7489
        2.0136C12.9097 2.18239 13 2.41131 13 2.65V19.75L10 17.5L7 19.75L4 17.5L1 19.75V2.65Z"
        stroke={fill}
        strokeWidth="2"
      />
    </svg>
  );
};

SubscriptionsIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fill: PropTypes.string,
};

SubscriptionsIcon.defaultProps = {
  width: 24,
  height: 24,
  fill: 'currentColor',
};

export default SubscriptionsIcon;
