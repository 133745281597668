import {
  SALES_MANAGERS_FETCH_HISTORY, SALES_MANAGERS_TOGGLE_MODAL, SALES_MANAGERS_TOGGLE_AND_SET_WARNING_MODAL,
  SALES_MANAGERS_SET_MANAGER_DATA, SALES_MANAGERS_SET_MODAL_DATA, SALES_MANAGERS_SET_MODAL_ERROR,
  SALES_MANAGERS_SET_TOOLTIP, SALES_MANAGERS_SET_PAGE, SALES_MANAGERS_FETCH_DATA, SALES_MANAGERS_RESET_MODAL
} from './types';

const today = new Date();

export const initialState = {
  data: [],
  links: {},
  meta: {},
  managersHistory: [],
  modalProps: {
    modalId: 'salesManagerModal',
    isModalOpen: false,
    isManagerOpen: false,
    managerSearchValue: ''
  },
  managersFields: {
    historyId: null,
    date: today,
    actualDay: today,
    disabledDay: today,
    manager: null,
    comment: '',
    isDatePickerOpen: false,
    disabledInput: false
  },
  modalWarningProps: {
    modalId: 'salesManagerWarningModal',
    isModalOpen: false,
    historyItemId: null,
    managerName: null
  },
  tipProps: {
    show: false,
    id: '',
    style: {}
  },
  queryParams: {
    filters: {
      name: '',
      type: '',
      show_inactive: false,
    },
    page: null,
    per_page: 100
  },
  modalErrors: {}
};

export default function salesManagers(state = initialState, action) {
  switch (action.type) {
    case SALES_MANAGERS_FETCH_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case SALES_MANAGERS_FETCH_HISTORY:
      return {
        ...state,
        managersHistory: action.history
      };
    case SALES_MANAGERS_RESET_MODAL:
      return {
        ...state,
        modalProps: initialState.modalProps,
        modalErrors: initialState.modalErrors,
        managersFields: initialState.managersFields,
      };
    case SALES_MANAGERS_TOGGLE_MODAL:
      return {
        ...state,
        modalProps: {
          ...state.modalProps,
          isModalOpen: action.isOpen
        },
      };
    case SALES_MANAGERS_TOGGLE_AND_SET_WARNING_MODAL:
      return {
        ...state,
        modalWarningProps: {
          ...state.modalWarningProps,
          ...action.data
        }
      };
    case SALES_MANAGERS_SET_MODAL_DATA:
      return {
        ...state,
        modalProps: {
          ...state.modalProps,
          ...action.data
        },
      };
    case SALES_MANAGERS_SET_MANAGER_DATA:
      return {
        ...state,
        managersFields: {
          ...state.managersFields,
          ...action.data
        }
      };
    case SALES_MANAGERS_SET_TOOLTIP:
      return {
        ...state,
        tipProps: action.tipProps
      };
    case SALES_MANAGERS_SET_PAGE:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          page: action.page
        },
      };
    case SALES_MANAGERS_SET_MODAL_ERROR:
      return {
        ...state,
        modalErrors: action.errors,
      };
    default:
      return state;
  }
}
