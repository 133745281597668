import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import UserHoverCardItemContent from './UserHoverCardItemContent';
import Button from '../Button/Button';
import styles from './sass/UserHoverCard.module.scss';

const UserHoverCardItems = ({ data }) => {
  const { t } = useTranslation('common');
  const router = useRouter();

  const rightNowData = data && data.rightNowList && data.rightNowList.length ? data.rightNowList[0] : null;

  return rightNowData ? (
    <div className={styles.content}>
      <div className={styles.rightNow}>
        <div className={styles.title}>{t('rightNow')}</div>
        <div className={styles.list}>
          <UserHoverCardItemContent data={rightNowData} isBlue />
        </div>
      </div>
      <Button
        size="sm"
        onClick={(event) => {
          event.stopPropagation();
          router.push(`/vacation?selectedEmployees=${data.id}`);
        }}
      >
        {t('viewVacation')}
      </Button>
    </div>
  ) : null;
};

UserHoverCardItems.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    rightNowList: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      start_date: PropTypes.string,
      end_date: PropTypes.string,
      user: PropTypes.shape({
        name: PropTypes.string,
      })
    })),
    upcomingList: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      start_date: PropTypes.string,
      end_date: PropTypes.string
    }))
  }),
};

UserHoverCardItems.defaultProps = {
  data: undefined,
};

export default UserHoverCardItems;
