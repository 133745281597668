import PropTypes from 'prop-types';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'next-i18next';
import Page from '../src/layout/page/Page';
import Title from '../src/components/Title/Title';
import { translateStatusCodeMap } from '../src/constants/httpStatusCodes';
import styles from '../src/pages/Error/sass/Error.module.scss';

const Error = ({ statusCode, isLoading }) => {
  const { t } = useTranslation('error');

  return (
    <Page isLoading={isLoading}>
      <div className={styles.errorBody}>
        <Title>
          {t(translateStatusCodeMap[statusCode])}
        </Title>
      </div>
    </Page>
  );
};

export const getServerSideProps = async (res, err) => ({
  props: {
    ...(await serverSideTranslations(res.locale, ['common', 'error'])),
    statusCode: res?.statusCode || err?.statusCode || 404,
  },
});

Error.propTypes = {
  statusCode: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
};

Error.defaultProps = {
  isLoading: false,
};

export default Error;
