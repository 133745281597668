import { batch, shallowEqual } from 'react-redux';
import { useTranslation } from 'next-i18next';
import Modal from '../Modal/Modal';
import {
  addOrEditReferencesAsync,
  fetchArticleAsync,
  resetModalErrors,
  setModalData,
  setModalErrors,
  setModalFields
} from '../../toolkitStore/helpCenter/slice';
import { useDispatch, useSelector } from '../../hooks/reduxToolkit';
import styles from './sass/HelpCenterModal.module.scss';
import FormLabel from '../Form/FormLabel';
import TextInput from '../TextInput/TextInput';

const HelpCenterModal = () => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const {
    isSaving, modalErrors, modalFields, helpCenterModal: modalProps
  } = useSelector(({ helpCenter }) => helpCenter, shallowEqual);

  const onSave = async () => {
    const article = await dispatch(fetchArticleAsync({ url: modalFields.link }));

    if (article.error?.message) {
      dispatch(setModalErrors({ link: 'linkIsRequired' }));
    } else {
      dispatch(addOrEditReferencesAsync(modalProps.isEdit ? modalFields.id : undefined));
    }
  };

  return (
    <Modal
      id="helpCenterModal"
      bodyClassName={styles.body}
      modalProps={modalProps}
      title={t(modalProps.isEdit ? 'edit' : 'addNewReferenceToThisPage')}
      buttonTitle={t('save')}
      buttonAction={onSave}
      error={modalErrors}
      closeModal={() => dispatch(setModalData({ isModalOpen: false }))}
      isSubmit={isSaving}
    >
      <FormLabel label={t('link')} isRequired />
      <TextInput
        value={modalFields.link}
        onChange={(event) => {
          batch(() => {
            dispatch(setModalFields({ link: event.target.value }));
            dispatch(resetModalErrors());
          });
        }}
        className={styles.linkInput}
        autoFocus
      />
    </Modal>
  );
};

export default HelpCenterModal;
