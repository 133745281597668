import PropTypes from 'prop-types';
import { employeeWorkStatusEmoji } from '../../constants/emoji';
import classNames from '../../utils/classNames';

import styles from './sass/Badge.module.scss';

const Vacation = ({ vacation, sizeStatus, isBorder }) => {
  const classes = classNames(styles.worked, styles[sizeStatus], {
    [styles.border]: isBorder,
  });

  return (
    <div className={classes}>
      {employeeWorkStatusEmoji[vacation]}
    </div>
  );
};

Vacation.propTypes = {
  vacation: PropTypes.oneOf(['vacation', 'dayOff', 'sickness']).isRequired,
  sizeStatus: PropTypes.oneOf(['small', 'medium', 'big']),
  isBorder: PropTypes.bool,
};

Vacation.defaultProps = {
  sizeStatus: 'small',
  isBorder: false,
};

export default Vacation;
