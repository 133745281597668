import PropTypes from 'prop-types';

const UsersMore = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 12C10.9853 12 13 9.98528 13 7.5C13 5.01472 10.9853 3 8.5 3C6.01472 3 4 5.01472 4 7.5C4 9.98528 6.01472 12 8.5 12ZM8.5 10C7.11929 10 6 8.88071 6 7.5C6 6.11929 7.11929 5 8.5 5C9.88071 5 11 6.11929 11 7.5C11 8.88071 9.88071 10 8.5 10ZM4 18V20C4 20.5523 3.55228 21 3 21C2.44772 21 2 20.5523 2 20V18C2 15.7909 3.79086 14 6 14H11C13.2091 14 15 15.7909 15 18V20C15 20.5523 14.5523 21 14 21C13.4477 21 13 20.5523 13 20V18C13 16.8954 12.1046 16 11 16H6C4.89543 16 4 16.8954 4 18ZM16 12C17.933 12 19.5 10.433 19.5 8.5C19.5 6.567 17.933 5 16 5C15.4477 5 15 5.44772 15 6C15 6.55228 15.4477 7 16 7C16.8284 7 17.5 7.67157 17.5 8.5C17.5 9.32843 16.8284 10 16 10C15.4477 10 15 10.4477 15 11C15 11.5523 15.4477 12 16 12ZM22 18V20C22 20.5523 21.5523 21 21 21C20.4477 21 20 20.5523 20 20V18C20 16.8954 19.1046 16 18 16C17.4477 16 17 15.5523 17 15C17 14.4477 17.4477 14 18 14C20.2091 14 22 15.7909 22 18Z"
      fill={fill}
    />
  </svg>
);

UsersMore.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fill: PropTypes.string,
};

UsersMore.defaultProps = {
  width: 24,
  height: 24,
  fill: 'currentColor',
};

export default UsersMore;
