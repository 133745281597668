import PropTypes from 'prop-types';

const LinkSimpleIcon = ({ width, height, fill }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.34766 12.651L12.651 7.34766" stroke={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.3257 13.9771L9.11604 16.1868C8.41274 16.8899 7.45897 17.2848 6.46452 17.2847C5.47007 17.2846 4.51638 16.8896 3.81319 16.1864C3.11001 15.4832 2.71493 14.5295 2.71484 13.5351C2.71476 12.5406 3.10968 11.5868 3.81274 10.8835L6.02245 8.67383" stroke={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.9771 11.3257L16.1868 9.11604C16.8899 8.41274 17.2848 7.45897 17.2847 6.46452C17.2847 5.47007 16.8896 4.51638 16.1864 3.81319C15.4832 3.11001 14.5295 2.71493 13.5351 2.71484C12.5406 2.71476 11.5868 3.10968 10.8835 3.81274L8.67383 6.02245" stroke={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
};

LinkSimpleIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fill: PropTypes.string,
};

LinkSimpleIcon.defaultProps = {
  width: 20,
  height: 20,
  fill: 'currentColor'
};

export default LinkSimpleIcon;
