import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { batch, shallowEqual } from 'react-redux';
import Sidebar from './sidebar/Sidebar';
import Header from './header/Header';
import { fetchApiInfo } from '../store/general/actions';
import { wsConnect, wsDisconnect } from '../store/websocket/actions';
import { useDispatch, useSelector as useReduxSelector } from '../hooks/redux';
import { useSelector } from '../hooks/reduxToolkit';
import styles from './sass/Layout.module.scss';

const Layout = ({ children }) => {
  const dispatch = useDispatch();

  const apiInfo = useReduxSelector(({ general }) => general.apiInfo, () => true);

  const {
    userIsLoading,
    userPermissions,
    passportId,
    profileId,
  } = useSelector(({ user }) => ({
    userIsLoading: user.isLoading,
    userPermissions: user.permissions,
    passportId: user.profile.passport_id,
    profileId: user.profile.id,
  }), shallowEqual);

  useEffect(() => {
    if (!userIsLoading && userPermissions.length > 0) {
      batch(() => {
        dispatch(wsConnect(profileId));

        if (!apiInfo.version) {
          dispatch(fetchApiInfo());
        }
      });
    }

    return () => {
      dispatch(wsDisconnect());
    };
  }, [userPermissions]);

  return (
    <>
      <Header />
      <div className={styles.container}>
        {process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' && (
          <img src={`https://my.onix.team/ping/${passportId}`} width="1" height="1" alt="icon" hidden />
        )}
        <Sidebar />
        {children}
      </div>
      <div id="modal-container" />
      <div id="dropdown-container" />
      <div id="alert-container" />
      <div id="tip-container" />
      <div id="hover-card-container" />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
