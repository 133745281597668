import PropTypes from 'prop-types';

const CopyIcon = ({ width, height, fill }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.375 4.95833V14.875H13.4583V4.95833H6.375ZM6.375 3.54166H13.4583C14.2375 3.54166 14.875 4.17916 14.875 4.95833V14.875C14.875 15.6542 14.2375 16.2917 13.4583 16.2917H6.375C5.59583 16.2917 4.95833 15.6542 4.95833 14.875V4.95833C4.95833 4.17916 5.59583 3.54166 6.375 3.54166Z"
        fill={fill}
      />
      <path
        d="M3.54167 12.0417C3.54167 12.4667 3.25833 12.75 2.83333 12.75C2.40833 12.75 2.125 12.4667 2.125 12.0417V2.12499C2.125 1.34583 2.7625 0.708328 3.54167 0.708328H11.3333C11.7583 0.708328 12.0417 0.991662 12.0417 1.41666C12.0417 1.84166 11.7583 2.12499 11.3333 2.12499H3.54167V12.0417Z"
        fill={fill}
      />
    </svg>
  );
};

CopyIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fill: PropTypes.string,
};

CopyIcon.defaultProps = {
  width: 17,
  height: 17,
  fill: 'currentColor',
};

export default CopyIcon;
