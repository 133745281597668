export const isProduction = process.env.NODE_ENV === 'production';
export const isServer = typeof window === 'undefined';

export const DEFAULT_ONIX_COMPANY = 'ONIX LTD';

export const PRE_SALE_PROJECT = 'Pre-sale';

export const WORKING_DAY_IN_SECONDS = 28800;
export const MONTHS = [
  'January', 'February', 'March', 'April', 'May', 'June', 'July',
  'August', 'September', 'October', 'November', 'December'
];
export const MONTHS_SHORT = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const WEEKDAYS_SHORT = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];
export const WEEKEND_DAYS = [6, 0];
export const FIRST_DAY = { en: 0, ru: 1 };
export const WORKDAY = 'workday';
export const WEEKEND = 'weekend';
export const HOLIDAY = 'holiday';
export const REPORTED_TIME = 'reported_time';
export const OVER_REPORTED_TIME = 'over_reported_time';
export const BILLED_TIME = 'billed_time';
export const OVER_BILLED_TIME = 'over_billed_time';
export const APPROVED_TIME = 'approved_time';
export const OVER_APPROVED_TIME = 'over_approved_time';
export const DATE = 'date';
export const OVERTIME = 'overtime';
export const FIXED = 'fixed';
export const REGULAR = 'regular';
export const UPDATED_AT = 'updated_at';
export const ISO_BASIC_TIME = 'T00:00:00';
export const BILLING_PERIODS = ['monthly', 'weekly', 'yearly', 'quarterly'];
export const CURRENCIES = {
  USD: 'USD', UAH: 'UAH', EUR: 'EUR', RUB: 'RUB', GBP: 'GBP'
};
export const CURRENCY_SIGNS = {
  USD: '$', UAH: '₴', EUR: '€', RUB: '₽', GBP: '£'
};
export const SALARY_DEPARTMENT_DATE_REQUIRED = '2021-01-01';
export const HOURS_CHART_Y = ['0', '2h', '3h', '4h', '5h', '6h', '7h', '80', '90', '100'];

// Holiday
export const HOLIDAYS = 'holidays';
export const FIXED_HOLIDAY = 'fixed';
export const FLOATING_HOLIDAY = 'floating';

// Guard name
export const GUARD_NAME_WEB = 'web';
export const GUARD_NAME_PROJECTS = 'projects';
export const GUARD_NAMES = [GUARD_NAME_WEB, GUARD_NAME_PROJECTS];

export const waitingListTableHeadItems = [
  'employees', 'availability', 'needJob', 'npa', 'experience',
  'department', 'manager', 'sm', 'comments', 'actions'
];
export const managerHistoryTableHeadItems = ['date', 'salesManager', 'changedBy', 'comment', 'action'];
export const salaryFOPTableHeadItems = [
  'employee', 'month', 'fop', 'fopAddress', 'fopAddressUkr', 'bankSwift', 'bankName', 'rentPrepaid', 'compensateEsv',
  'compensateRent', 'bankAccountCost', 'name', 'nameUkr', 'accountUSD', 'accountUAH', 'agreementType', 'en',
  'taxIdNumber', 'passportNumber', 'createdAt', 'action'
];
export const salaryBaseTableHeadItems = [
  'employee', 'startDate', 'useBuilder', 'rate', 'rateType', 'rateBench', 'cardNumber', 'preferredPaymentMethod',
  'department', 'overTimeHourlyRate', 'loggingMode', 'vacationFactor', 'leaveFactor', 'createdAt', 'createdBy',
  'comment', 'action'
];
export const salaryBonusTableHeadItems = [
  'employee', 'month', 'amount', 'category', 'client', 'project', 'department', 'gpnlMonth', 'gpnlUser', 'createdAt',
  'createdBy', 'comment', 'action'
];
export const salaryCorrectionTableHeadItems = [
  'employee', 'month', 'amount', 'amountRate', 'amountUah', 'type', 'createdAt', 'createdBy',
  'comment', 'action'
];
export const salaryTableHeadItems = [
  'employee', 'month', 'planHrs', 'reported', 'approved', 'billed', 'dayOff', 'sL', 'vacation',
  'amount', 'action'
];
export const invoiceTableHeadItems = ['number', 'item', 'category', 'rate', 'hours', 'amount'];

export const clientAgreementsTableHeadItems = [
  'startDate', 'name', 'isUpwork', 'service', 'endDate', 'nonDisclosure', 'nonCompete', 'clientCompany',
  'ourCompany', 'paymentMethod', 'currency', 'comments', 'action'
];

export const assetsTableHeadItems = [
  'category', 'createdAt', 'name', 'inventoryNumber', 'serialNumber', 'partNumber', 'eanNumber', 'employee',
  'department', 'sharedDevice', 'amount', 'currency', 'currencyRate', 'description', 'action'
];

export const projectsTableHeadItems = [
  'project', 'client', 'product', 'contractType', 'manager', 'salesManager', 'type', 'department', 'lastWorklog',
  'firstWorklog', 'approvedHours', 'billedHours'
];

// Timesheet
export const EMPTY_WORKLOG_TIMES = {
  reported_time: 0,
  billed_time: 0,
  approved_time: 0,
  over_approved_time: 0,
  over_billed_time: 0,
  over_reported_time: 0,
};
export const REPORTED = 'reported';
export const OVER_REPORTED = 'over_reported';
export const BILLED = 'billed';
export const OVER_BILLED = 'over_billed';
export const APPROVED = 'approved';
export const OVER_APPROVED = 'over_approved';
export const TIMESHEET_TIME_TYPES = [REPORTED, OVER_REPORTED, BILLED, OVER_BILLED, APPROVED, OVER_APPROVED];

// Sort
export const SORT_BY_NAME = 'name';
export const SORT_BY_CLIENT_NAME = 'client_name';
export const SORT_BY_CLIENT_PRODUCT_NAME = 'client_product_name';
export const SORT_BY_PROJECT_NAME = 'project_name';
export const SORT_BY_MANAGER_NAME = 'manager_name';
export const SORT_BY_SALES_MANAGER_NAME = 'sales_manager_name';
export const SORT_BY_TYPE = 'type';
export const SORT_BY_CONTRACT_TYPE = 'contract_type';
export const SORT_BY_CREATED_AT = 'created_at';
export const SORT_BY_UPDATED_AT = 'updated_at';
export const SORT_BY_KEY = 'key';
export const SORT_BY_CREATED_BY = 'created_by';
export const SORT_BY_DATE = 'date';
export const SORT_BY_USER = 'user_name';
export const SORT_BY_TICKET_NAME = 'ticket_name';
export const SORT_BY_ITEM_NAME = 'milestone_item_name';
export const SORT_BY_EMAIL = 'email';
export const SORT_BY_DEPARTMENT = 'department';
export const SORT_BY_LEAD_NAME = 'lead_name';
export const SORT_BY_START_DATE = 'start_date';
export const SORT_BY_END_DATE = 'end_date';
export const SORT_BY_CATEGORY = 'category';
export const SORT_BY_EMPLOYEE = 'user';
export const SORT_BY_RATE = 'rate';
export const SORT_BY_START_MONTH = 'start_month';
export const SORT_BY_AMOUNT = 'amount';
export const SORT_BY_COUNT = 'count';
export const SORT_BY_APPROVED_AMOUNT = 'approved_amount';
export const SORT_BY_MONTH = 'month';
export const SORT_BY_STATUS = 'status';
export const SORT_BY_LAST_WORKLOG_DATE = 'last_worklog_date';
export const SORT_BY_FIRST_WORKLOG_DATE = 'first_worklog_date';
export const SORT_BY_FIRST_BILLED_DATE = 'first_billed_date';
export const SORT_BY_AMOUNT_TOTAL = 'amount_total';
export const SORT_BY_WORKLOAD = 'planned_approved_time';
export const SORT_BY_BILLABILLITY = 'billability';
export const SORT_BY_DEPARTMENT_NAME = 'department_name';
export const SORT_BY_APPROVED_TIME = 'approved_time';
export const SORT_BY_BILLED_TIME = 'billed_time';
export const SORT_BY_EXPECTED_PAYMENT_DATE = 'expected_payment_date';
export const SORT_BY_CLIENT_PRODUCT_MILESTONE_NAME = 'client_product_milestone_name';

// Employee Flags
export const employeeFlagsTableHeadItems = ['startDate', 'department'];

// Loader colors
export const LOADER_COLOR_WHITE = 'white';
export const LOADER_COLOR_BLUE = 'blue';
export const LOADER_COLOR_GREEN = 'green';
export const LOADER_COLORS = [LOADER_COLOR_WHITE, LOADER_COLOR_BLUE, LOADER_COLOR_GREEN];

// Salary rate
export const salaryRateTableHeadItems = ['month', 'bankRate', 'cardRate', 'fopRate', 'action'];

// Salary FOP info
export const salaryFopInfoTableHeadItems = ['month', 'rentUah', 'esvUah', 'fopEn', 'action'];

// Salary Anomalies
export const WARNING = 'warning';
export const SENDING_ERROR = 'sending-error';
export const STATUS_ANOMALY = 'anomaly_status';
export const STATUS_EMAIL = 'email_status';
export const STATUS_XERO = 'xero_status';
export const SALARY_ANOMALIES_ACTIONS = {
  SEND_EMAIL: 'send/email',
  SEND_XERO: 'send/xero',
  DEALLOCATE_XERO_OVERPAYMENTS: 'deallocate-xero-overpayments',
  RECALCULATE: 'recalculate',
  APPROVE: 'approve'
};

// Projects items
export const projectsItemsHeadItems = [
  'date', 'name', 'type', 'rateType', 'rate', 'currency', 'action'
];

// Report
export const REPORTS = '/reports';
export const REPORTS_TIME_TRACKING = `${REPORTS}/time_tracking`;
export const REPORT_TIME_FORMAT_OPTIONS = {
  hours: 'hh mm',
  percentage: 'hh',
};

// Сomments
export const employeeCommentsTableHeadItems = ['date', 'hourlyRate', 'monthlyRate'];
export const commentEmployeeAccessModalData = ['Admin', 'HR', 'Project Manager'];

// Employee Skills
export const employeeSkillsTableHeadItems = [
  'skill', 'projects', 'howUse', 'level', 'lastUsage', 'status', 'approver', 'action'
];

// CV Generator
export const TEMPLATEBUTTONS = ['gray', 'blue', 'darkBlue'];

// Report
export const projectReportDetailsColumn = [
  'reportItem', 'date', 'epic', 'ticket', 'user', 'comment', 'total', 'billed_time', 'mergeComments'
];

// CV
export const LANGUAGESLEVELS = [
  'elementary', 'lower-intermediate', 'intermediate', 'upper-intermediate', 'advanced', 'native'
];

export const PROJECTKEYS = ['Project Name:', 'Description:', 'Role:', 'Technologies:', 'Link:'];

// Technology tree
export const technologyUsersTableHeadItems = [
  'department', 'name', 'howUse', 'project', 'level', 'lastUsage', 'status', 'approver', 'works'
];

// Client report
export const clientReportSummaryHeadItemsBilled = ['reportItem', 'hourlyRate', 'billedHours', 'sum'];

export const clientReportSummaryHeadItemsApprove = ['reportItem', 'hourlyRate', 'timeSpent'];

// Client dashboard
export const CLIENT_DASHBOARD_TABS = {
  comments: 'hydraClientsComments',
  reports: 'hrmPmReports',
  notes: 'pipedriveNotes',
};

// Client milestone tabs
export const CLIENT_MILESTONE_TABS = {
  items: 'milestoneItems',
  invoices: 'invoices',
  tickets: 'milestoneTickets',
};

// Systems departments
export const GMP_SCHEMA_PARAMS = 'gmp';
export const BHR_SCHEMA_PARAMS = 'bhr';
export const SCHEMAS_OF_DEPARTMENT = [GMP_SCHEMA_PARAMS, BHR_SCHEMA_PARAMS];

export const CUSTOM_TAB = 'custom';
export const ALL_EMPLOYEES_TAB = 'allEmployees';

// Global Search
export const GLOBAL_SEARCH_OPTIONS = {
  all: 'all',
  users: 'users',
  position: 'position',
  projects: 'projects',
  clients: 'clients',
  project_tickets: 'project_tickets',
  client_companies: 'client_companies',
  client_products: 'client_products',
  invoices: 'invoices',
  other: 'other',
};

export const GLOBAL_SEARCH_TABS = ['all', 'users', 'position', 'projects', 'clients', 'other'];

export const GLOBAL_SEARCH_MIN_INPUT_LENGTH = 3;

// Expense reports
export const EXPENSE_REPORT_MODAL_TYPE = {
  create: 'create',
  review: 'review',
  edit: 'edit',
};
export const PREFERRED_PAYMENT_METHOD_CARD = 'Card';

export const COMPANY_POSITIONS = {
  CEO: 'Chief Executive Officer',
  CTO: 'Chief Technical Officer'
};

export const TRASHED = 'trashed';
