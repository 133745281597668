import { shallowEqual } from 'react-redux';
import Link from 'next/link';
import HeaderAppsList from './components/HeaderAppsList';
import HeaderProfileMenu from './components/HeaderProfileMenu';
import HeaderNavigation from './components/HeaderNavigation';
import HelpCenter from '../../components/HelpCenter/HelpCenter';
import Logo from '../../components/Logo/Logo';
import GlobalSearch from './components/GlobalSearch';
import { useSelector } from '../../hooks/reduxToolkit';
import { isAllowed } from '../../utils/helpers';
import useMediaQuery from '../../hooks/useMediaQuery';
import { HELP_ARTICLE_MANAGEMENT, HELP_ARTICLE_READ } from '../../constants/permissions';
import styles from './sass/Header.module.scss';

const Header = () => {
  const isMobile = useMediaQuery('(max-width: 767px)');

  const { isShowHelp } = useSelector(({ user }) => ({
    isShowHelp: isAllowed([HELP_ARTICLE_MANAGEMENT, HELP_ARTICLE_READ], user.permissions),
  }), shallowEqual);

  return (
    <header className={styles.header}>
      {isMobile && <HeaderNavigation />}
      <div className={styles.logo}>
        <Link href="/"><Logo /></Link>
      </div>
      <GlobalSearch />
      <div className={styles.features}>
        <HeaderAppsList />
        {isShowHelp && <HelpCenter />}
        <HeaderProfileMenu />
      </div>
    </header>
  );
};

export default Header;
