import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../services/axios';
import { LIST_SIZE_PER_PAGE } from '../../constants/values';
import { SORT_BY_NAME } from '../../constants/common';

export const initialState = {
  isLoading: false,
  isSubmit: false,
  error: undefined,
  data: [],
  meta: {},
  modalProps: {
    isModalOpen: false,
    modalId: 'milestoneItemModal',
    milestoneItemId: null,
    isCopy: false,
  },
  deleteModal: {
    modalId: 'deleteModal',
    isModalOpen: false,
    milestoneItem: null,
  },
  modalErrors: {},
  isEdit: false,
  expandedRowId: null,
  query: {
    filters: {
      name: '',
      user: {
        search: ''
      },
      id: [],
      client_id: [],
      client_product_id: [],
      client_product_milestone: {
        id: [],
        search: ''
      },
      project: {
        id: []
      }
    },
    sort: SORT_BY_NAME,
    with: ['users', 'client_product_milestone', 'agreement_service'],
    page: null,
    per_page: LIST_SIZE_PER_PAGE,
  },
};

export const fetchMilestoneItemsAsync = createAsyncThunk(
  'milestoneItems/fetchMilestoneItems',
  async (arg, { getState, rejectWithValue }) => {
    try {
      const { query } = getState().milestoneItems;
      const { data, status } = await axios.get('/milestone_items', { params: query });

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteMilestoneItemAsync = createAsyncThunk(
  'milestoneItems/deleteMilestoneItem',
  async ({ milestoneItemId }, { dispatch, rejectWithValue }) => {
    try {
      const { data, status } = await axios.delete(`/milestone_items/${milestoneItemId}`);

      if (status === 204) {
        dispatch(fetchMilestoneItemsAsync());
        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const milestoneItemsSlice = createSlice({
  name: 'milestoneItems',
  initialState,
  reducers: {
    resetState: () => initialState,
    setQuery: (state, action) => {
      state.query = { ...state.query, ...action.payload };
    },
    setQueryFilter: (state, action) => {
      state.query.filters = { ...state.query.filters, ...action.payload };
    },
    setModalData: (state, action) => {
      state[action.payload.key] = { ...state[action.payload.key], ...action.payload.data };
    },
    resetModalFields: (state) => {
      state.modalProps = initialState.modalProps;
    },
    setExpandedRowId: (state, action) => {
      state.expandedRowId = action.payload === state.expandedRowId ? null : action.payload;
    },
    setData: (state, action) => {
      return ({ ...state, ...action.payload });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMilestoneItemsAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchMilestoneItemsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
      })
      .addCase(fetchMilestoneItemsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(deleteMilestoneItemAsync.pending, (state) => {
        state.isSubmit = true;
      })
      .addCase(deleteMilestoneItemAsync.fulfilled, (state) => {
        state.isSubmit = false;
        state.deleteModal = initialState.deleteModal;
      })
      .addCase(deleteMilestoneItemAsync.rejected, (state, action) => {
        state.isSubmit = false;
        state.error = action.payload;
      });
  },
});

export const {
  setQueryFilter,
  setModalData,
  resetModalFields,
  setData,
  setExpandedRowId,
  setQuery,
  resetState,
} = milestoneItemsSlice.actions;

export default milestoneItemsSlice.reducer;
