import PropTypes from 'prop-types';
import CopyIcon from '../Icons/CopyIcon';
import CheckCircleIcon from '../Icons/CheckCircleIcon';
import useCopyToClipboard from '../../hooks/useCopyToClipboard';
import styles from './sass/ClipboardCopy.module.scss';

const ClipboardCopy = ({
  icon, children, className, copyText,
}) => {
  const [value, copy, setCopiedText] = useCopyToClipboard();

  const handleCopy = async ({ target }) => {
    if (target.nodeName !== 'A') {
      await copy(copyText);
    }
  };

  const handleMouseLeave = () => {
    setCopiedText(null);
  };

  return (
    <div
      role="presentation"
      onClick={handleCopy}
      onMouseLeave={handleMouseLeave}
      className={`${styles.wrapper} ${className}`}
    >
      {children}
      <span className={styles.icon}>
        {value ? <CheckCircleIcon /> : (icon || <CopyIcon />)}
      </span>
    </div>
  );
};

ClipboardCopy.propTypes = {
  children: PropTypes.node.isRequired,
  copyText: PropTypes.string.isRequired,
  className: PropTypes.string,
  icon: PropTypes.node,
};

ClipboardCopy.defaultProps = {
  className: '',
  icon: null,
};

export default ClipboardCopy;
