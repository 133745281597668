import { SET_IS_MOBILE, FETCH_API_INFO } from './types';
import { authGet } from '../../utils/apiCall';

export const setIsMobile = (isMobile) => ({ type: SET_IS_MOBILE, isMobile });

export const fetchApiInfo = () => {
  return (dispatch) => {
    authGet(
      `${process.env.NEXT_PUBLIC_API_BACKEND_URL}/app/info`,
      {},
      { Accept: 'application/json', 'Content-Type': 'application/json' }
    ).then((res) => {
      if (res && !res.message) {
        dispatch({ type: FETCH_API_INFO, apiInfo: res });
      }
    });
  };
};
