import {
  EMPLOYEE_PERMISSIONS_FETCH_DATA, EMPLOYEE_PERMISSIONS_FETCH_PERMISSIONS, EMPLOYEE_PERMISSIONS_SET_MODAL_DATA,
  EMPLOYEE_PERMISSIONS_SET_REMOVE_MODAL_DATA, EMPLOYEE_PERMISSIONS_FETCH_ROLES, EMPLOYEE_PERMISSIONS_SET_SUBMIT
} from './types';

export const initialState = {
  data: [],
  permissions: [],
  rolesPermissions: [],
  isDeleteSubmit: false,
  addPermissionsModalProps: {
    isModalOpen: false,
    modalId: 'addPermissionsModal',
    name: '',
    selectedPermissions: [],
    addPermissions: [],
    removePermissions: [],
    disabledPermissions: [],
    permissions: [],
  },
  removeModalProps: {
    modalId: 'removeModal',
    isModalOpen: false,
    permission: null,
  }
};

export default function employeePermissions(state = initialState, action) {
  switch (action.type) {
    case EMPLOYEE_PERMISSIONS_SET_SUBMIT:
      return {
        ...state,
        [action.key]: action.value,
      };
    case EMPLOYEE_PERMISSIONS_FETCH_ROLES:
      return {
        ...state,
        rolesPermissions: action.rolesPermissions,
      };
    case EMPLOYEE_PERMISSIONS_FETCH_PERMISSIONS:
      return {
        ...state,
        permissions: action.permissions,
      };
    case EMPLOYEE_PERMISSIONS_FETCH_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case EMPLOYEE_PERMISSIONS_SET_REMOVE_MODAL_DATA:
      return {
        ...state,
        removeModalProps: {
          ...state.removeModalProps,
          ...action.data
        },
      };
    case EMPLOYEE_PERMISSIONS_SET_MODAL_DATA:
      return {
        ...state,
        addPermissionsModalProps: {
          ...state.addPermissionsModalProps,
          ...action.data
        },
      };
    default:
      return state;
  }
}
