import Router from 'next/router';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../services/axios';
import { newValidateErrors, validateErrors, validateProjectCreate } from '../../utils/validators';

export const initialState = {
  isLoading: true,
  isSubmit: false,
  isClientDialogOpen: false,
  isShowClientFields: false,
  createFields: {
    name: '',
    key: '',
    product: null,
    client: null,
    company: null,
    manager: null,
    techLead: null,
    department: null,
    under_nda: false,
    type: '',
    type_custom_category: '',
    business_domains: '',
    initial_client_problem: '',
    solution_we_provided: '',
    our_unique_expertise: '',
    allow_overtime: false,
    contract_type: null,
  },
  dropDown: {
    isProduct: false,
    isClient: false,
    isCompany: false,
    isManager: false,
    isType: false,
    isDepartment: false,
    isContractType: false,
  },
  inputSearch: {
    manager: '',
    department: '',
    client: '',
    company: '',
  },
  createErrors: {},
  error: {},
};

export const createProjectAsync = createAsyncThunk(
  'projectCreate/addProject',
  async (arg, { getState, rejectWithValue }) => {
    try {
      const { createFields, isShowClientFields } = getState().projectCreate;

      const errors = validateErrors(
        createFields,
        validateProjectCreate,
        {
          isActive: isShowClientFields,
        }
      );

      if (errors) {
        return rejectWithValue({ isFormValidation: true, errors });
      }

      const { data, status } = await axios.post('/projects', {
        name: createFields.name,
        key: createFields.key,
        business_domains: createFields.business_domains,
        technologies: createFields.technologies,
        initial_client_problem: createFields.initial_client_problem,
        solution_we_provided: createFields.solution_we_provided,
        our_unique_expertise: createFields.our_unique_expertise,
        under_nda: createFields.under_nda,
        manager_id: createFields.manager.id,
        tech_lead_id: createFields.techLead?.id,
        type: createFields.type,
        department_id: createFields.department?.id,
        client_product_id: createFields.product?.id,
        type_custom_category: createFields.type_custom_category,
        allow_overtime: createFields.allow_overtime,
        contract_type: createFields.contract_type,
      });

      if (status === 201 || status === 200) {
        await Router.push(`/projects/${data.id}`);
        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const projectCreateSlice = createSlice({
  name: 'projectCreate',
  initialState,
  reducers: {
    resetState: () => initialState,
    setFieldsData: (state, action) => {
      state.createFields = { ...state.createFields, ...action.payload };
      state.createErrors = {
        ...state.createErrors,
        ...newValidateErrors(
          { ...action.payload },
          validateProjectCreate,
          {
            isActive: state.isShowClientFields,
          }
        ),
      };
    },
    setData: (state, action) => {
      return ({ ...state, ...action.payload });
    },
    setFormData: (state, action) => {
      state.dropDown = { ...state.dropDown, ...action.payload };
    },
    setSearchFields: (state, action) => {
      state.inputSearch = { ...state.inputSearch, ...action.payload };
    },
    setShowClientFields: (state, action) => {
      state.isShowClientFields = action.payload;

      if (!action.payload) {
        state.createFields.client = null;
        state.createFields.product = null;
        state.createFields.contract_type = null;
        state.createErrors.client = null;
        state.createErrors.product = null;
        state.createErrors.contract_type = null;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createProjectAsync.pending, (state) => {
        state.isLoading = true;
        state.isSubmit = true;
        state.error = undefined;
        state.createErrors = {};
      })
      .addCase(createProjectAsync.fulfilled, (state) => {
        state.isSubmit = false;
        state.isLoading = false;
        state.dropDown = initialState.dropDown;
        state.createFields = initialState.createFields;
        state.createErrors = initialState.createErrors;
      })
      .addCase(createProjectAsync.rejected, (state, action) => {
        if (action.payload.isFormValidation) {
          state.createErrors = action.payload.errors;
        } else {
          state.error = action.payload;
        }
        state.isSubmit = false;
        state.isLoading = false;
      });
  },
});

export const {
  resetState,
  setData,
  setFieldsData,
  setFormData,
  setSearchFields,
  setShowClientFields
} = projectCreateSlice.actions;

export default projectCreateSlice.reducer;
