import PropTypes from 'prop-types';
import { Content } from '@radix-ui/react-hover-card';
import Avatar from '../Avatar/Avatar';
import ClipboardCopy from '../ClipboardCopy/ClipboardCopy';
import CalendarEmptyIcon from '../Icons/CalendarEmpty';
import Bag from '../Icons/Bag';
import Badge from '../Badge/Badge';
import Vacation from '../Badge/Vacation';
import UsersMore from '../Icons/UsersMore';
import styles from './sass/UserSearchCard.module.scss';

const UserSearchCard = ({
  username,
  avatar,
  email,
  delivery,
  positions,
  experience,
  onVacation,
}) => {
  return (
    <Content sideOffset={10} align="start" alignOffset={100} className={styles.content}>
      <div className={styles.root}>
        <div className={styles.wrapper}>
          <div className={styles.imageBox}>
            <Badge statuses={onVacation ? <Vacation vacation="vacation" sizeStatus="medium" /> : undefined}>
              <Avatar image={avatar} alt="avatar" width={48} height={48} />
            </Badge>
          </div>
          <div className={styles.headInfo}>
            <ClipboardCopy copyText={username}>
              <p className={styles.name}>
                {username}
              </p>
            </ClipboardCopy>
            <ClipboardCopy copyText={email}>
              <p className={styles.email}>
                {email}
              </p>
            </ClipboardCopy>
          </div>
        </div>
        <div className={styles.divider} />
        <ul className={styles.list}>
          {delivery ? (
            <li className={styles.item}>
              <Bag width={20} height={20} />
              <p>{delivery}</p>
            </li>
          ) : null}
          {positions ? (
            <li className={styles.item}>
              <UsersMore width={20} height={20} fill="#8891A3" />
              <p>{positions}</p>
            </li>
          ) : null}
          {experience ? (
            <li className={styles.item}>
              <CalendarEmptyIcon stroke="#8891A3" />
              <p>{experience}</p>
            </li>
          ) : null}
        </ul>
      </div>
    </Content>
  );
};

UserSearchCard.propTypes = {
  username: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  email: PropTypes.string,
  delivery: PropTypes.string,
  experience: PropTypes.string,
  positions: PropTypes.string,
  onVacation: PropTypes.bool,
};

UserSearchCard.defaultProps = {
  avatar: '',
  email: '',
  delivery: '',
  positions: '',
  experience: '',
  onVacation: false,
};

export default UserSearchCard;
