import PropTypes from 'prop-types';
import { useRef } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'next-i18next';
import Button from '../Button/Button';
import IconButton from '../Button/IconButton';
import Close from '../Icons/Close';
import useOnKeydown from '../../hooks/useOnKeydown';
import { MODAL_STOP_TYPE, MODAL_SUCCESS_TYPE, MODAL_WARNING_TYPE } from '../../constants/types';
import styles from './sass/Modal.module.scss';

const Modal = ({
  id,
  title,
  children,
  className,
  titleClassName,
  bodyClassName,
  contentClassName,
  bottomClassName,
  modalProps: { isModalOpen, modalId },
  closeModal,
  buttonTitle,
  cancelTitle,
  buttonAction,
  rejectAction,
  disableBtn,
  isSubmit,
  isShowBottomPanel,
  isShowCancelBtn,
  error,
  type,
  buttonActionRed,
  buttonActionColor,
  note,
}) => {
  const { t } = useTranslation(['common', 'error']);
  const modal = document.getElementById('modal-container');
  const ref = useRef(null);
  const errorKeys = Object.keys(Object.entries(error).filter(([, value]) => value).reduce((acc, [key, value]) => {
    return { ...acc, [key]: value };
  }, {}));
  let colorButtonAction = 'primary';

  if (buttonActionColor) {
    colorButtonAction = buttonActionColor;
  } else if (type === MODAL_WARNING_TYPE || buttonActionRed) {
    colorButtonAction = 'error';
  }

  useOnKeydown(ref, 'Escape', () => {
    if (isModalOpen) {
      closeModal();
    }
  });

  return (
    isModalOpen && id === modalId ? (
      createPortal(
        (
          <div id={id} className={`${styles.wrap} ${type ? styles[type] : ''} ${className}`}>
            <div ref={ref} className={`${styles.body} ${bodyClassName}`}>
              {title && (
                <div className={`${styles.title} ${titleClassName}`}>
                  <p>{title}</p>
                </div>
              )}
              <div className={`${styles.content} ${contentClassName}`}>
                {children}
              </div>
              {isShowBottomPanel && (
                <div className={`${styles.bottom} ${bottomClassName}`}>
                  {errorKeys.length > 0 && !note && (
                    <div className={styles.error}>
                      <p>
                        {errorKeys.map((item) => (item === 'message'
                          ? error[item] : t(`error:${error[item]}`))).join('. ')}
                      </p>
                    </div>
                  )}
                  {note && (
                    <div className={styles.note}>
                      <span>{note}</span>
                    </div>
                  )}
                  <div className={styles.buttons}>
                    {isShowCancelBtn && (
                      <Button
                        size="lg"
                        variant="outline"
                        color="default"
                        ariaLabel={`cancel_${id}`}
                        onClick={() => closeModal(false)}
                      >
                        {cancelTitle || t('cancel')}
                      </Button>
                    )}
                    {rejectAction && (
                      <Button
                        size="lg"
                        variant="outline"
                        color="error"
                        ariaLabel={`reject_${id}`}
                        isDisabled={disableBtn}
                        onClick={rejectAction}
                      >
                        {`${t('reject')}...`}
                      </Button>
                    )}
                    {buttonTitle && buttonAction && (
                      <Button
                        size="lg"
                        color={colorButtonAction}
                        ariaLabel={`apply_${id}`}
                        isDisabled={disableBtn}
                        isLoading={isSubmit}
                        onClick={buttonAction}
                      >
                        {buttonTitle}
                      </Button>
                    )}
                  </div>
                </div>
              )}
              {errorKeys.length > 0 && note && (
                <div className={styles.padding}>
                  <div className={styles.error}>
                    <p>
                      {errorKeys.map((item) => t(`error:${error[item]}`)).join('. ')}
                    </p>
                  </div>
                </div>
              )}
              <div className={styles.close}>
                <IconButton
                  icon={<Close />}
                  ariaLabel="close modal"
                  size="sm"
                  variant="ghost"
                  onClick={() => closeModal(false)}
                />
              </div>
            </div>
          </div>
        ),
        modal
      )
    ) : null
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  modalProps: PropTypes.shape({
    isModalOpen: PropTypes.bool,
    modalId: PropTypes.string,
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
  className: PropTypes.string,
  titleClassName: PropTypes.string,
  bodyClassName: PropTypes.string,
  bottomClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  title: PropTypes.oneOf([PropTypes.string, PropTypes.node]),
  buttonTitle: PropTypes.string,
  cancelTitle: PropTypes.string,
  buttonAction: PropTypes.func,
  rejectAction: PropTypes.func,
  disableBtn: PropTypes.bool,
  isShowBottomPanel: PropTypes.bool,
  isShowCancelBtn: PropTypes.bool,
  isSubmit: PropTypes.bool,
  error: PropTypes.objectOf(PropTypes.string),
  type: PropTypes.oneOf([MODAL_WARNING_TYPE, MODAL_SUCCESS_TYPE, MODAL_STOP_TYPE]),
  buttonActionRed: PropTypes.bool,
  note: PropTypes.string,
  buttonActionColor: PropTypes.oneOf(['error', 'primary', 'secondary']),
};

Modal.defaultProps = {
  className: '',
  titleClassName: '',
  bodyClassName: '',
  bottomClassName: '',
  contentClassName: '',
  title: undefined,
  buttonTitle: undefined,
  cancelTitle: undefined,
  disableBtn: false,
  isSubmit: false,
  isShowBottomPanel: true,
  isShowCancelBtn: true,
  buttonAction: undefined,
  rejectAction: undefined,
  error: {},
  type: undefined,
  note: undefined,
  buttonActionRed: false,
  buttonActionColor: undefined,
};

export default Modal;
