import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import addMonths from 'date-fns/addMonths';
import subMonths from 'date-fns/subMonths';
import axios from '../../services/axios';
import { parseToMonthInt } from '../../utils/helpers';

const date = new Date();
const fromDate = subMonths(date, 5);
const toDate = addMonths(date, 1);
const fromMonth = parseToMonthInt(fromDate.getFullYear(), fromDate.getMonth() + 1);
const toMonth = parseToMonthInt(toDate.getFullYear(), toDate.getMonth() + 1);

export const initialState = {
  isLoading: false,
  error: undefined,
  data: [],
  meta: {},
  query: {
    filters: {
      start_month: fromMonth,
      end_month: toMonth,
    },
    page: null,
  },
  pageFilters: {
    employeeName: '',
    isEmployeeOpen: false,
    employee: {},
  },
  monthsPeriod: {
    from: fromMonth,
    to: toMonth,
  },
  tableHeadData: {
    employeeValue: '',
    accountValue: '',
  },
  previewModal: {
    modalId: 'previewModal',
    isModalOpen: false,
    html: ''
  },
};

export const fetchBalanceAsync = createAsyncThunk(
  'salaryBalance/fetchBalance',
  async (arg, { rejectWithValue, getState }) => {
    try {
      const { salaryBalance: { query, pageFilters: { employee: { id } } } } = getState();
      const { data, status } = await axios.get(`/users/${id}/salary_balance`, { params: query });

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const balanceSlice = createSlice({
  name: 'salaryBalance',
  initialState,
  reducers: {
    resetState: () => initialState,
    setQuery: (state, action) => {
      state.query = { ...state.query, ...action.payload };
    },
    setQueryFilter: (state, action) => {
      state.query.filters = { ...state.query.filters, ...action.payload };
    },
    setPageFilterData: (state, action) => {
      state.pageFilters = { ...state.pageFilters, ...action.payload };
    },
    setMonthPeriodData: (state, action) => {
      state.monthsPeriod = { ...state.monthsPeriod, ...action.payload };
    },
    setTableHeadData: (state, action) => {
      state.tableHeadData = action.payload.tableHeadData;
    },
    setModalData: (state, action) => {
      state[action.payload.key] = { ...state[action.payload.key], ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBalanceAsync.pending, (state) => {
        state.isLoading = true;
        state.projects = undefined;
        state.error = undefined;
      })
      .addCase(fetchBalanceAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
      })
      .addCase(fetchBalanceAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const {
  resetState,
  setQuery,
  setQueryFilter,
  setPageFilterData,
  setMonthPeriodData,
  setModalData,
  setTableHeadData,
} = balanceSlice.actions;

export default balanceSlice.reducer;
