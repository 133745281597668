import { FETCH_API_INFO } from './types';

export const initialState = {
  apiInfo: {},
};

export default function general(state = initialState, action) {
  switch (action.type) {
    case FETCH_API_INFO:
      return {
        ...state,
        apiInfo: action.apiInfo,
      };
    default:
      return state;
  }
}
