import PropTypes from 'prop-types';

const VacationIcon = ({ width, height, fill }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path d="M13.2266 3.79325L13.2266 3.79326C17.2092 4.85741 19.7242 9.04516 19.0027 13.5163L15.2006 12.5003L11.1866 11.4277L7.17267 10.3551L7.17266 10.3551L3.37055 9.33917C4.97537 5.10415 9.24393 2.72901 13.2266 3.79325Z" stroke={fill} strokeWidth="2" />
        <path fillRule="evenodd" clipRule="evenodd" d="M15.5088 1.42069C18.7526 2.83451 20.9823 5.75508 21.788 9.15116C23.1222 8.25329 24 6.72915 24 5C24 2.23858 21.7614 0 19 0C17.6417 0 16.4099 0.541627 15.5088 1.42069Z" fill="#ECBD2C" />
        <path d="M13.2262 3.79326L13.2262 3.79327C13.4463 3.85207 13.7144 4.03324 13.9782 4.49353C14.2445 4.95836 14.462 5.6388 14.5832 6.52327C14.7939 8.06103 14.6927 10.0626 14.213 12.2365L11.1862 11.4277L8.15941 10.6189C8.82797 8.49548 9.73865 6.71022 10.6883 5.48252C11.2345 4.77639 11.7624 4.29514 12.2251 4.02511C12.6833 3.75771 13.0061 3.73444 13.2262 3.79326Z" stroke={fill} strokeWidth="2" />
        <rect x="10.2891" y="10.9087" width="2" height="10" transform="rotate(14.5 10.2891 10.9087)" fill={fill} />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

VacationIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fill: PropTypes.string,
};

VacationIcon.defaultProps = {
  width: 24,
  height: 24,
  fill: 'currentColor',
};

export default VacationIcon;
